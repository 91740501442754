import { SortService } from './sort.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit {

  sortForm: FormGroup = new FormGroup({
    field: new FormControl(''),
    direction: new FormControl(''),
  });

  constructor(private sortService: SortService) { }

  ngOnInit(): void {
    const sortValue = this.sortService.getSortValue();
    this.sortForm.controls['field'].setValue(sortValue.field);
    this.sortForm.controls['direction'].setValue(sortValue.direction);

    this.sortForm.valueChanges.subscribe(sortFormValue => {
      this.sortChanged(sortFormValue);
    })
  }

  sortChanged = (sortValue) => {
    this.sortService.setSortValue(sortValue);
  }

}
