import { IOrganizationModel } from 'src/app/shared/interfaces';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectOrgService } from './select-org.service';

@Component({
  selector: 'app-select-org',
  templateUrl: './select-org.component.html',
  styleUrls: ['./select-org.component.scss']
})
export class SelectOrgComponent implements OnInit {
  selectedValueSubscription: Subscription;

  organizationList: IOrganizationModel[];

  selectForm: FormGroup = new FormGroup({
    select: new FormControl([])
  })

  constructor(
    private selectOrgService: SelectOrgService
  ) { }

  ngOnInit(): void {
    this.organizationList = this.selectOrgService.organizationList;
    this.selectForm.controls['select'].setValue(this.selectOrgService.getSelectedValue());
    this.selectedValueSubscription = this.selectForm.valueChanges.subscribe(selectFormValue => {
      this.onSelectChange(selectFormValue.select);
    });
  }

  onSelectChange = (selectValue) => {
    this.selectOrgService.setSelectedValue(selectValue);
  }
}
