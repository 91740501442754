import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { ConfigsService } from '../../core/services/configs.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {IInspectionModel} from '../interfaces';
// import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  private sortValue = new BehaviorSubject<any>({
    field: 'date',
    direction: 'dsc'
  });
  currentSortValue = this.sortValue.asObservable();
  selectedLanguage: string;

  constructor(private configsService: ConfigsService, private translateService: TranslateService) {
    this.selectedLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  setSortValue = (sortValue) => {
    this.sortValue.next(sortValue);
  }

  getSortValue = () => {
    return this.sortValue.value;
  }

  sortInspections = (inspections) => {
    if (this.sortValue.value.field === 'date') {
      return this.sortByDate(inspections);
    } else {
      return this.sortByType(inspections);
    }
  }

  sortByDate = (inspections) => {
    if (this.sortValue.value.direction === 'asc') {
      // Asc (Older First)
      return inspections.sort((a, b) => {
        return this.parseDate(a.lastUpdated) > this.parseDate(b.lastUpdated) ? 1 : this.parseDate(a.lastUpdated) < this.parseDate(b.lastUpdated) ? -1 : 0;
      });
    } else {
      // Dsc (Newer First)
      return inspections.sort((b, a) => {
        return this.parseDate(a.lastUpdated) > this.parseDate(b.lastUpdated) ? 1 : this.parseDate(a.lastUpdated) < this.parseDate(b.lastUpdated) ? -1 : 0;
        // return (new Date(a.lastUpdated).getTime() > new Date(b.lastUpdated).getTime()) ? 1 : (new Date(a.lastUpdated).getTime() < new Date(b.lastUpdated).getTime()) ? -1 : 0;
      });
    }
  }

  parseDate = (date) => {
    return new Date(date.replace(/-/g, '/').replace('T', ' ').replace(/\..*|\+.*/, ''));
  }


  // Null values won't give error
  sortByType = (inspections: IInspectionModel[]) => {
    return inspections.sort((a, b) => {
      const languageA = this.configsService.configs[a.jsonVersion].languages[this.selectedLanguage] ? this.selectedLanguage : 'de';
      const languageB = this.configsService.configs[b.jsonVersion].languages[this.selectedLanguage] ? this.selectedLanguage : 'de';
      if (!a.dataModel.machineSubCategoryKey) { return -1; }
      if (!b.dataModel.machineSubCategoryKey) { return +1; }
      if (this.sortValue.value.direction === 'asc') {
        return (this.configsService.configs[a.jsonVersion].languages[languageA][a.dataModel.machineSubCategoryKey] || a.dataModel.machineSubCategoryKey).localeCompare(this.configsService.configs[b.jsonVersion].languages[languageB][b.dataModel.machineSubCategoryKey] || b.dataModel.machineSubCategoryKey);
      } else {
        return (this.configsService.configs[b.jsonVersion].languages[languageB][b.dataModel.machineSubCategoryKey] || b.dataModel.machineSubCategoryKey).localeCompare(this.configsService.configs[a.jsonVersion].languages[languageA][a.dataModel.machineSubCategoryKey] || a.dataModel.machineSubCategoryKey);
      }
    });
  }
}
