<div fxFlex="column" class="confirm dialog">

  <div class="dialog-header">
    {{ title }}
  </div>

  <div class="dialog-container" fxLayout="column">

    <div class="dialog-body">
      {{ text }}
    </div>

    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center" [fxLayoutAlign.lt-sm]="hideCancelBtn ? 'space-around center' : 'space-between'">
      <button *ngIf="!hideCancelBtn" class="btn btn-grey" mat-button type="button" (click)="onCancel()">
        {{ cancelBtn || 'cancel' | translate }}
      </button>
      <button class="btn btn-primary" mat-button type="button" (click)="onConfirm()">
        {{ confirmBtn || 'ok' | translate }}
      </button>
    </div>
  </div>
</div>
