import { MachinePicturesComponent } from './machine-pictures/machine-pictures.component';
import { BaseDataComponent } from './base-data/base-data.component';
import { SecurePipe } from './pipes/secure.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './modules/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FilterComponent } from './filter/filter.component';
import { SortComponent } from './sort/sort.component';
import { SearchComponent } from './search/search.component';
import { CarouselComponent } from './carousel/carousel.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { CsvExportDialogComponent } from './csv-export-dialog/csv-export-dialog.component';
import { SelectOrgComponent } from './select-org/select-org.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    FilterComponent,
    SortComponent,
    SearchComponent,
    SecurePipe,
    CarouselComponent,
    InputDialogComponent,
    BaseDataComponent,
    MachinePicturesComponent,
    CsvExportDialogComponent,
    SelectOrgComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    ConfirmDialogComponent,
    FilterComponent,
    SortComponent,
    SearchComponent,
    SecurePipe,
    CarouselComponent,
    BaseDataComponent,
    MachinePicturesComponent,
    SelectOrgComponent
  ]
})
export class SharedModule { }
