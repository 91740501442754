import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from './../../core/services/snackbar.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MustMatch } from 'src/app/core/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  emailToken: string = '';
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  passwordViolation: string[] = [];

  resetPasswordForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private router :Router
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.emailToken = params.emailToken;
    });

    this.resetPasswordForm = this.formBuilder.group({
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]]
    }, {
      validator: [MustMatch('password', 'confirmPassword')]
    })
  }

  togglePasswordVisibility = () => {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility = () => {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onUpdatePassword = () => {
    this.passwordViolation = [];
    this.authService.resetPassword({newPassword: this.resetPasswordForm.value.password, emailToken: this.emailToken}).subscribe(response => {
      if (response.passwordPolicyViolations) {
        response.passwordPolicyViolations.forEach(element => {
          switch (element.violation) {
            case "PASSWORD_TOO_SHORT":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-too-short', { hint: element.hint }));
              break;
            case "PASSWORD_TOO_LONG":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-too-long', {hint: element.hint }));
              break;
            case "MISSING_UPPER_CASE_LETTER":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-missing-upper-case-letter'));
              break;
            case "MISSING_LOWER_CASE_LETTER":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-missing-lower-case-letter'));
              break;
            case "MISSING_NUMBER":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-missing-number'));
              break;
            case "MISSING_SPECIAL_CHARACTER":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-missing-special-character'));
              break;
            case "CONTAINS_BLACKLISTED_WORD":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-contains-blacklisted-word'));
              break;
            case "CONTAINS_CONSECUTIVE_CHARACTERS":
              this.passwordViolation.push(this.translateService.instant('account.reset-password.password-violations.password-contains-consecutive-characters'));
              break;
          }
        });
      } else {
        this.snackBarService.success(this.translateService.instant('toast.success.reset-pass'), 'X', 2000);
        this.router.navigate(['login']);
      } 
    });
  }

}
