import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  // milliseconds
  private readonly duration: number = 5000;

  constructor(public snackBar: MatSnackBar) {}

  private openSnackBar(
    message: string,
    className: string,
    action?: string,
    duration?: number
  ) {
    this.snackBar.open(message, action, {
      duration,
      panelClass: [className],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  success(message: string, action?: string, duration: number = this.duration) {
    this.openSnackBar(
      message,
      'snack-bar-success',
      action,
      duration
    );
  }

  primary(message: string, action?: string, duration: number = this.duration) {
    this.openSnackBar(
      message,
      'snack-bar-primary',
      action,
      duration
    );
  }

  warn(message: string, action?: string, duration: number = this.duration) {
    this.openSnackBar(
      message,
      'snack-bar-warn',
      action,
      duration
    );
  }

  error(message: string, action?: string, duration: number = this.duration) {
    this.openSnackBar(
      message,
      'snack-bar-error',
      action,
      duration
    );
  }

  default(message: string, action?: string, duration: number = this.duration) {
    this.openSnackBar(message, '', action, action ? null : duration);
  }
}
