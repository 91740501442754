import { UserRoles } from './../../shared/enums/user-roles.enum';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    return this.authService.currentUser.pipe(
      take(1),
      map(user => {
        let isAuth: boolean;
        !!user ? isAuth = user.userModel.role === UserRoles.Admin ? true : user.userModel.agbsAccepted : isAuth = false;
        if(isAuth) return true
        this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
        return false
      })
    )
  }
}
