import { ComponentTypes } from '../enums/component-types.enum';
import { HelperService } from '../../core/services/helper.service';
import { InspectionStates } from 'src/app/shared/enums/inspection-states.enum';
import { TranslateService } from '@ngx-translate/core';
import { sellingOptions } from '../enums/selling-options.enum';
import { IComponentModel } from '../interfaces';
import { Component, Input, OnInit } from '@angular/core';
import { IInspectionModel } from 'src/app/shared/interfaces';
import * as _ from 'lodash';

@Component({
  selector: 'app-base-data',
  templateUrl: './base-data.component.html',
  styleUrls: ['./base-data.component.scss']
})
export class BaseDataComponent implements OnInit {
  @Input() config: any;
  @Input() section: any;
  @Input() selectedLanguage: string;
  @Input() inspectionDetail: IInspectionModel;
  @Input() previousInspectionDetail?: IInspectionModel;
  @Input() isComparison?: boolean;
  @Input() inspectionType?: string;
  @Input() columnLayout?: boolean;
  @Input() comparisonSection?: any;

  componentsParsed: boolean = false;
  previousSection: any;
  machineCategory: string;
  ComponentTypes = ComponentTypes;
  unavailableArray: string[] = ['not_available', 'not_determinable', 'not_readable', 'unknown', 'no', 'no_edition'];
  componentsToInsert = [];
  previousComponentsToInert = [];

  constructor(
    private translateService: TranslateService,
    private helperService: HelperService,
  ) { }

  ngOnInit(): void {
    this.machineCategory = this.inspectionDetail.dataModel.machineCategoryKey;
    this.section = JSON.parse(JSON.stringify(this.section));
    this.addDamageComponents(this.section);

    if(this.isComparison) {
      this.previousSection = JSON.parse(JSON.stringify(this.section));
      this.addDamageComponents(this.previousSection);
      this.compareInspections();
    }
    this.parseInspectionDetailBaseData(this.section, this.inspectionDetail, false);

    if(this.isComparison) {
      this.parseInspectionDetailBaseData(this.previousSection, this.previousInspectionDetail, true);
    }
    this.createColumnLayout();

    if(this.isComparison) this.compareInspections();
    if(this.comparisonSection) {
      this.compareSpecialComponents();
    }
  }

  compareSpecialComponents = () => {
    if(this.inspectionType == 'IK') {
      this.section.columns.map((column, columnIndex) => {
        column.subCategories.map((category, categoryIndex) => {
          category.components.map((component, componentIndex) => {
            const comparisonComponent = this.comparisonSection.columns[columnIndex].subCategories[categoryIndex].components[componentIndex];
            comparisonComponent['values'] = [];
            this.parseSpecialComponents(comparisonComponent, this.previousInspectionDetail);

            if(comparisonComponent.values[0] != component.values[0]) {
              comparisonComponent['isEqual'] = false;
              component['isEqual'] = false;
            } else {
              comparisonComponent['isEqual'] = true;
              component['isEqual'] = true;
            }

            if(component.title == this.translateService.instant('us-comment-ik')) {
              comparisonComponent['isEqual'] = true;
              component['isEqual'] = true;
            } else if(component.title == this.translateService.instant('us-comment-ie')) {
              comparisonComponent['isEqual'] = false;
              component['isEqual'] = false;
            }
          })
        })
      })
    }

  }

  addDamageComponents = (section) => {
    if(section.title == 'damages') {
      section.columns.map((column, columnIndex) => {
        column.subCategories.map((category, categoryIndex) => {
          if(category.title == 'extension_devices') {
            let extensionDamageComponents = [];
            category.components.map((component) => {
              extensionDamageComponents.push(component);
              Object.keys(this.inspectionDetail.components).map(componentId => {
                if(componentId.startsWith(component.componentID+'_')) {
                  extensionDamageComponents.push({
                    componentID: componentId,
                    width: 49.7
                  });
                }
              })
            })
            section.columns[columnIndex].subCategories[categoryIndex].components = extensionDamageComponents;
          }
        })
      })
    }
  }

  compareInspections = () => {
    this.section.columns.map((column, columnIndex) => {
      column.subCategories.map((category, categoryIndex) => {
        category.components.map((component, componentIndex) => {
          if(component.title != 'product-category') {
            let previousComponent = this.previousSection.columns[columnIndex].subCategories[categoryIndex].components[componentIndex];

            const inspectionComponent = this.inspectionDetail.components[component.componentID];
            const previousInspectionComponent = this.previousInspectionDetail.components[component.componentID];
            if(inspectionComponent && previousInspectionComponent) {
              const isEqual = _.isEqual(inspectionComponent.booleanValues, previousInspectionComponent.booleanValues)
                              && _.isEqual(inspectionComponent.languageKeys, previousInspectionComponent.languageKeys)
                              && _.isEqual(inspectionComponent.textValues, previousInspectionComponent.textValues)
                              && _.isEqual(inspectionComponent.urlValues, previousInspectionComponent.urlValues);
              if(component) component['isEqual'] = isEqual;
              if(previousComponent) previousComponent['isEqual'] = isEqual;
              if(component['specialCheck']) {
                const isEqual = _.isEqual(inspectionComponent.booleanValues, previousInspectionComponent.booleanValues)
                if(component) component['isEqual'] = isEqual;
                if(previousComponent) previousComponent['isEqual'] = isEqual;
              }
            }
          } else {
            component['isEqual'] = true;
            this.previousSection.columns[columnIndex].subCategories[categoryIndex].components[componentIndex]['isEqual'] = true;
          }
        })
      })
    })
  }

  parseInspectionDetailBaseData = (section, inspectionDetail:IInspectionModel, isPerviousInspection: boolean) => {
    section.columns.map((column, columnIndex) => {
      column.subCategories.map((category, categoryIndex) => {
        category['isVisible'] = false;
        let componentsToRemove = [];
        category.components.map((component, componentIndex) => {
          component['type'] = ComponentTypes.normal;
          if(component?.componentID === null) {
            category['isVisible'] = true;
            component['values'] = [];

           this.parseSpecialComponents(component, inspectionDetail);
            component.title = this.translateService.instant(component.title);
          } else if(component.componentID && !component['title']){
            let inspectionComponent = inspectionDetail.components[component.componentID];

            if(inspectionComponent == undefined &&
              (this.config.machines[this.machineCategory].components[component.componentID]['component_type'] == 'radio_button'
              || this.config.machines[this.machineCategory].components[component.componentID]['component_type'] == 'checkbox')) {
                if (this.config.machines[this.machineCategory].components[component.componentID]['product_categories'].includes(inspectionDetail?.dataModel?.machineSubCategoryKey)) {
                  inspectionComponent = {
                    booleanValues: [false],
                    componentIdentifier: component.componentID,
                    componentName: this.config.machines[this.machineCategory].components[component.componentID].name,
                    componentType: this.config.machines[this.machineCategory].components[component.componentID]['component_type'],
                    itemid: "",
                    languageKeys: ["no"],
                    textValues: [],
                    type: 4,
                    urlValues: [],
                  }
                  inspectionDetail.components[component.componentID] = inspectionComponent;
                };
            }

            if(inspectionComponent) {
              component['values'] = [];
              component['urls'] = [];
              // component['type'] = ComponentTypes.normal;
              component.title = this.config.languages[this.selectedLanguage][inspectionComponent.componentName] || inspectionComponent.componentName || '';
              const configComponent = this.config.machines[this.machineCategory].components[component.componentID];
              this.computeInspectionValues(component, inspectionComponent, configComponent, isPerviousInspection, columnIndex, categoryIndex, componentIndex, category.title);
              if(component['values'].length>0) category['isVisible'] = true;
            }

            if(!inspectionComponent || (inspectionComponent && inspectionComponent.componentType == 'radio_button_extension_devices')) {
              componentsToRemove.push(componentIndex);
            }
          }
        });
        componentsToRemove.reverse().map(index => {
          category.components.splice(index, 1);
        });
      })
    });
    this.insertMultipleBooleanSubComponents(section, isPerviousInspection ? this.previousComponentsToInert : this.componentsToInsert);
  }

  parseSpecialComponents = (component, inspectionDetail) => {
    if(component.title == "inspection-date") {
      component.values.push(inspectionDetail[component.valueKey] ?
        this.helperService.parseGermanDate(new Date(inspectionDetail[component.valueKey])) :
        this.config.languages[this.selectedLanguage]['unknown']);

      if(!inspectionDetail[component.valueKey]) {
        component['unavailable'] = true;
      }
    }

    else if(component.title == "inspection-date-ik" || component.title == "inspection-date-ie") {
      component.values.push(inspectionDetail[component.valueKey] ?
        this.helperService.parseGermanDate(new Date(inspectionDetail[component.valueKey])) :
        this.config.languages[this.selectedLanguage]['unknown']);

      if(!inspectionDetail[component.valueKey]) {
        component['unavailable'] = true;
      }
    }

    else if(component.title == "delivery-date") {
      component.values.push(inspectionDetail[component.valueKey][component.subValueKey] ?
        this.helperService.parseGermanDate(new Date(inspectionDetail[component.valueKey][component.subValueKey])) :
        this.config.languages[this.selectedLanguage]['unknown']);

      if(!inspectionDetail[component.valueKey][component.subValueKey]) {
        component['unavailable'] = true;
      }
    }

    else if(component.title == "selling-option") {
      let sellingOption = inspectionDetail[component.valueKey][component.subValueKey];
      component.values.push(this.config.languages[this.selectedLanguage][sellingOptions[sellingOption]] || sellingOptions[sellingOption]  || "-");
    }

    else if(component.title == "actual-price") {
      // if(inspectionDetail.inspectionState == InspectionStates.WON) { // Deep Shah confirm with Nadine
        component.values.push(this.config.languages[this.selectedLanguage][inspectionDetail[component.valueKey][component.subValueKey]] || inspectionDetail[component.valueKey][component.subValueKey]  || "-");
      // }
      component.values[0] > this.inspectionDetail.priceInfoModel.maxPrice ? component['matIcon'] = 'error_outline' : null
    }

    else if(component.title == "inspector-comment") {
      // if(inspectionDetail.inspectionState == InspectionStates.LOST || (inspectionDetail.inspectionState == InspectionStates.WON && inspectionDetail.priceInfoModel.actualPrice > inspectionDetail.priceInfoModel.maxPrice)) { // Deep Shah confirm with Nadine
        component.values.push(this.config.languages[this.selectedLanguage][inspectionDetail[component.valueKey][component.subValueKey]] || inspectionDetail[component.valueKey][component.subValueKey] || "-");
        component['isComment'] = true;
      // }
    }

    else if(component.title == "us-comment-ik") {
      component['isComment'] = true;
      if(this.inspectionType == null || this.inspectionType == 'IK') {
        component.values.push(this.config.languages[this.selectedLanguage][inspectionDetail[component.valueKey][component.subValueKey]] || inspectionDetail[component.valueKey][component.subValueKey] || "-");
      } else {
        component.values.push(this.config.languages[this.selectedLanguage][this.previousInspectionDetail[component.valueKey][component.subValueKey]] || this.previousInspectionDetail[component.valueKey][component.subValueKey] || "-");
      }
    }

    else if(component.title == "us-comment-ie") {
      component['isComment'] = true;
      if(this.inspectionType == 'IK') {
        component.values.push("-");
      } else {
        component.values.push(this.config.languages[this.selectedLanguage][inspectionDetail[component.valueKey][component.subValueKey]] || inspectionDetail[component.valueKey][component.subValueKey] || "-");
      }
    }

    else if(component.title == "status") {
      component.values.push('');
      component['icon'] = InspectionStates[inspectionDetail.inspectionState];
    }

    else if(component.superSubValueKey) {
      component.values.push(this.config.languages[this.selectedLanguage][inspectionDetail[component.valueKey][component.subValueKey][component.superSubValueKey]] || inspectionDetail[component.valueKey][component.subValueKey][component.superSubValueKey]  || "-");
    }

    else {
      component.values.push(this.config.languages[this.selectedLanguage][inspectionDetail[component.valueKey][component.subValueKey]] || inspectionDetail[component.valueKey][component.subValueKey]  || "-");
    }

    if(component.title.includes('-price') && component.values[0] != null) {
      component.values[0] && component.values[0] > 0 ? component.values[0] = component.values[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + ' ' + this.inspectionDetail.currency : component.values[0] = '-';
    }

    if(!this.comparisonSection) {
      component['isEqual'] = true;
    }
  }

  createColumnLayout = () => {
    this.section.columns.map((column, columnIndex) => {
      column['isVisible'] = false;
      column.subCategories.map((category, categoryIndex) => {
        category['zeroColumn'] = [];
        category['firstColumn'] = [];
        category['secondColumn'] = [];

        let firstColumnHeight = 0;
        let secondColumnHeight = 0;
        category.components.map((component, componentIndex) => {
          column['isVisible'] = true;
          if(component.width == '100') {
            this.section.columns[columnIndex].subCategories[categoryIndex]['zeroColumn'].push(component);
          } else {
            if(firstColumnHeight <= secondColumnHeight) {
              category['firstColumn'].push(component);
              firstColumnHeight += component.type == 'normal' ? 1 : 3
            } else {
              category['secondColumn'].push(component);
              secondColumnHeight += component.type == 'normal' ? 1 : 3
            }
          }
        });

        if(secondColumnHeight - firstColumnHeight >= 2) {
          const lastComponentFirstColumn = _.cloneDeep(category['firstColumn'][category['firstColumn'].length - 1]);
          const lastComponentSecondColumn = _.cloneDeep(category['secondColumn'][category['secondColumn'].length - 1]);
          category['firstColumn'].splice(-1, 1);
          category['firstColumn'].push(lastComponentSecondColumn);
          category['secondColumn'].splice(-1, 1);
          category['secondColumn'].push(lastComponentFirstColumn);
        }
      })
    })
  }

  computeInspectionValues = (component, inspectionComponent: IComponentModel, configComponent, isPerviousInspection:boolean, columnIndex?:number, categoryIndex?:number, componentIndex?: number, categoryTitle?: string) => {
    switch(inspectionComponent.componentType) {
      // Component Types
      case 'checkbox': {
        this.addBooleanValue(component, inspectionComponent);
        break;
      }
      case 'checkbox_many': {
        this.addMultipleBooleanValue(component, inspectionComponent, configComponent, columnIndex, categoryIndex, isPerviousInspection);
        break;
      }
      case 'image': {
        this.addImageUrls(component, inspectionComponent);
        break;
      }
      case 'free_text': {
        this.addTextValues(component, inspectionComponent);
        break;
      }
      case 'dropdown': {
        this.addLanguageKeys(component, inspectionComponent, configComponent);
        break;
      }
      case 'dropdown_countries': {
        this.addLanguageKeys(component, inspectionComponent);
        break;
      }
      case 'radio_button': {
        this.addLanguageKeys(component, inspectionComponent);
        break;
      }
      case 'damage_radio_button_image_optional_free_text': {
        this.addDamageImagesWithTitles(component, inspectionComponent, categoryTitle == 'extension_devices' ? componentIndex : null, configComponent, columnIndex, categoryIndex, isPerviousInspection);
        break;
      }
      case 'free_text_checkbox_not_available': {
        this.addFreeTextIfNoCheckbox(component, inspectionComponent, configComponent);
        break;
      }
      case 'free_text_number_decimal_checkbox_not_available': {
        this.addFreeTextIfNoCheckbox(component, inspectionComponent, configComponent);
        break;
      }
      case 'free_text_checkbox_not_available_manufacturer_quick_change_device': {
        this.addFreeTextIfNoCheckbox(component, inspectionComponent, configComponent);
        break;
      }
      case 'free_text_checkbox_not_available_rightaligned': {
        this.addFreeTextIfNoCheckbox(component, inspectionComponent, configComponent);
        break;
      }
      case 'free_text_number_checkbox_not_available': {
        this.addFreeTextIfNoCheckbox(component, inspectionComponent, configComponent);
        break;
      }
      case 'image_checkbox_not_available': {
        this.addImagesIfNoCheckbox(component, inspectionComponent, configComponent);
        break;
      }
      case 'image_radio_button_not_available': {
        this.addImageIfRadioButtonNotAvailable(component, inspectionComponent, configComponent);
        break;
      }
      case 'radio_button_image_when_no': {
        this.addImageIfNoRadioButton(component, inspectionComponent, configComponent, columnIndex, categoryIndex, isPerviousInspection);
        break;
      }
      case 'dropdown_free_text_other_last_element': {
        this.addFreeTextIfNoLanguageKey(component, inspectionComponent);
        break;
      }
      case 'radio_button_image': {
        this.addImageIfYesRadioButton(component, inspectionComponent);
        break;
      }
      case 'radio_button_checkbox_not_available': {
        this.addRadioButtonCheckBox(component, inspectionComponent, configComponent)
        break;
      }
      case 'addon_radio_button_image_optional_free_text': {
        // this.addImagesWithTitles(component, inspectionComponent) // Deep Shah to verify with Bina
        this.addDamageImagesWithTitles(component, inspectionComponent, null, configComponent, columnIndex, categoryIndex, isPerviousInspection)
        break;
      }
      //Special Component Types
      case 'free_text_checkbox_operating_hours': {
        this.addFreeTextIfNoCheckbox(component, inspectionComponent, configComponent)
        break;
      }
      case 'dropdown_year_of_manufacture': {
        this.addLanguageKeys(component, inspectionComponent)
        break;
      }
      case 'free_text_checkbox_serialnumber': {
        this.addFreeTextIfNoCheckbox(component, inspectionComponent, configComponent)
        break;
      }
      case 'free_text_type': {
        this.addTextValues(component, inspectionComponent)
        break;
      }
      case 'dropdown_manufacturer': {
        this.addFreeTextIfNoLanguageKey(component, inspectionComponent)
        break;
      }
      case 'radio_button_extension_devices': {
        this.addExtensionDevices(component, inspectionComponent, isPerviousInspection)
        break;
      }
    }
  }

  addBooleanValue = (component, inspectionComponent: IComponentModel) => {
    if(inspectionComponent.booleanValues[0]) {
      component.values.push(this.config.languages[this.selectedLanguage].yes);
    } else {
      component.values.push(this.config.languages[this.selectedLanguage].no);
      component['unavailable'] = true;
    }
  }

  addMultipleBooleanValue = (component, inspectionComponent: IComponentModel, configComponent, columnIndex: number, categoryIndex: number, isPerviousInspection: boolean) => {
    let subComponents = [];
    inspectionComponent.booleanValues.map((booleanValue, index) => {
      subComponents.push({
        componentID: null,
        width: 49.7,
        type: ComponentTypes.normal,
        title: this.config.languages[this.selectedLanguage][configComponent.input_values?.checkbox_values[index]] || configComponent.input_values?.checkbox_values[index],
        values: [this.config.languages[this.selectedLanguage][booleanValue ? 'yes' : 'no']],
        unavailable: this.unavailableArray.includes(booleanValue ? 'yes' : 'no') ? true : false,
        isEqual: this.isComparison && _.isEqual(booleanValue, this.previousInspectionDetail?.components[component.componentID]?.booleanValues[index])
      });
    });
    let componentsToInsertArray = isPerviousInspection ? this.previousComponentsToInert : this.componentsToInsert;
    componentsToInsertArray.push({
      columnIndex,
      categoryIndex,
      componentID: component.componentID,
      subComponents
    });
  }

  addImageUrls = (component, inspectionComponent: IComponentModel) => {
    component.type = ComponentTypes.carousel;
    inspectionComponent.urlValues.map((url, index) => {
      component.values[index] = this.config.languages[this.selectedLanguage][inspectionComponent.componentName] || inspectionComponent.componentName;
      component.urls[index] = url;
    })
  }

  addTextValues = (component, inspectionComponent: IComponentModel) => {
    component.values = inspectionComponent.textValues;
  }

  addLanguageKeys = (component, inspectionComponent: IComponentModel, configComponent?) => {
    inspectionComponent.languageKeys.map(languageKey => {
      component.values.push(this.config.languages[this.selectedLanguage][languageKey] || languageKey);
      if(configComponent) {
        component['unit'] = this.config.languages[this.selectedLanguage][configComponent.unit] || configComponent.unit;
      }
      if(this.unavailableArray.includes(languageKey)) {
        component['unavailable'] = true;
      }
    })
  }

  addImagesWithTitles = (component, inspectionComponent: IComponentModel) => {
    component.type = ComponentTypes.carousel;
    let dotIndex = 0
    inspectionComponent.urlValues.map(urlValue => {
      if(urlValue == '.') {
        dotIndex++;
      } else {
        component.urls.push(urlValue);
        component.values.push(inspectionComponent.textValues[dotIndex]);
      }
    });
  }

  addDamageImagesWithTitles = (component, inspectionComponent: IComponentModel, damageComponentIndex: number, configComponent, columnIndex: number, categoryIndex: number, isPerviousInspection: boolean) => {
    component.type = ComponentTypes.carousel;
    const componentName = this.config.languages[this.selectedLanguage][inspectionComponent.componentName];
    if(inspectionComponent.urlValues.length > 0 || inspectionComponent.textValues.length > 0) {
      if(damageComponentIndex !== null) {
        component.values.push(componentName + ' ' + (damageComponentIndex + 1));
      } else if(this.section.title == 'damages') {
        // component.values.push(componentName + ' (' + this.config.languages[this.selectedLanguage][component.suffix] + ')');
        const perspective = this.config.machines[this.machineCategory].components[component.componentID].perspective;
        component.values.push(componentName + ' (' + this.config.languages[this.selectedLanguage][perspective] + ')');
      } else {
        component.values.push(componentName);
      }
    }

    let subComponents = [];
    let dotIndex = 0;
    let urlValuesObject = {};
    let descriptionObject = {};
    inspectionComponent.urlValues.map(urlValue => {
      if(urlValue == '.') {
        dotIndex++;
      } else {
        if(!urlValuesObject[dotIndex]) urlValuesObject[dotIndex] = [];
        if(!descriptionObject[dotIndex]) descriptionObject[dotIndex] = [];
        urlValuesObject[dotIndex].push(urlValue);
        descriptionObject[dotIndex].push(inspectionComponent.textValues[dotIndex]);
      }
    });

    for (let index = 0; index <= dotIndex; index++) {
      subComponents.push({
        componentID: null,
        width: 49.7,
        type: ComponentTypes.carousel,
        title: '',
        values: [component.values[0]],
        descriptions: descriptionObject[index],
        urls: urlValuesObject[index],
        // isEqual: this.isComparison && _.isEqual(booleanValue, this.previousInspectionDetail?.components[component.componentID].booleanValues[index])
        isEqual: true
      });
    }

    let componentsToInsertArray = isPerviousInspection ? this.previousComponentsToInert : this.componentsToInsert;
    componentsToInsertArray.push({
      columnIndex,
      categoryIndex,
      componentID: component.componentID,
      subComponents
    });

  }

  addFreeTextIfNoCheckbox = (component, inspectionComponent: IComponentModel, configComponent) => {
    if(inspectionComponent.booleanValues.length > 0 && inspectionComponent.booleanValues[0]) {
      component.values.push(this.config.languages[this.selectedLanguage][configComponent.input_values.checkbox_values[0]] || configComponent.input_values.checkbox_values[0])
      component['specialCheck'] = true;
      if(this.unavailableArray.includes(configComponent.input_values.checkbox_values[0])) {
        component['unavailable'] = true;
      }
    } else {
      if((inspectionComponent.componentName?.includes('weight') || inspectionComponent.componentName?.includes('power') || inspectionComponent.componentName?.includes('hours') || inspectionComponent.componentName?.includes('payload')) && inspectionComponent.textValues[0] != null) {
        component.values[0] = Number(inspectionComponent.textValues[0]).toLocaleString('de');
      } else {
        component.values = inspectionComponent.textValues;
      }
      component['unit'] = this.config.languages[this.selectedLanguage][configComponent.unit] || configComponent.unit;
    }
  }

  addImagesIfNoCheckbox = (component, inspectionComponent: IComponentModel, configComponent) => {
    if(inspectionComponent.booleanValues.length > 0 && inspectionComponent.booleanValues[0]) {
      component.values.push(this.config.languages[this.selectedLanguage][configComponent.input_values.checkbox_values[0]] || configComponent.input_values.checkbox_values[0])
      component['specialCheck'] = true;
      if(this.unavailableArray.includes(configComponent.input_values.checkbox_values[0])) {
        component['unavailable'] = true;
      }

    } else {
      component.type = ComponentTypes.carousel;

      inspectionComponent.urlValues.map((url, index) => {
        component.values[index] = this.config.languages[this.selectedLanguage][inspectionComponent.componentName] || inspectionComponent.componentName;
        component.urls[index] = url;
      })
    }
  }

  // addFreeTextIfNoRadioButton = (component, inspectionComponent: IComponentModel, configComponent) => {
  //   if(inspectionComponent.booleanValues.length > 0 && inspectionComponent.booleanValues[0]) {
  //     component.values.push(this.config.languages[this.selectedLanguage][configComponent.input_values.radio_button_values[0]] || configComponent.input_values.radio_button_values[0])
  //   } else {
  //     component.values = inspectionComponent.textValues;
  //   }
  // }

  addImageIfNoRadioButton = (component, inspectionComponent: IComponentModel, configComponent, columnIndex: number, categoryIndex: number, isPerviousInspection: boolean) => {
    if(inspectionComponent.languageKeys.length > 0 && inspectionComponent.languageKeys[0] == 'yes') {
      component.type = ComponentTypes.normal;
      component.values.push(this.config.languages[this.selectedLanguage]['yes']);
    } else {
      component.type = ComponentTypes.carousel;
      inspectionComponent.urlValues.map((url, index) => {
        component.values[index] = this.config.languages[this.selectedLanguage][inspectionComponent.componentName] || inspectionComponent.componentName;
        component.urls[index] = url;
      });

      let componentsToInsertArray = isPerviousInspection ? this.previousComponentsToInert : this.componentsToInsert;
      componentsToInsertArray.push({
        columnIndex,
        categoryIndex,
        componentID: component.componentID,
        subComponents: [
          {
            componentID: null,
            width: 49.7,
            type: ComponentTypes.normal,
            title: this.config.languages[this.selectedLanguage][inspectionComponent.componentName] || inspectionComponent.componentName,
            values: [this.config.languages[this.selectedLanguage][inspectionComponent.languageKeys[0]]],
            unavailable: true,
            isEqual: this.isComparison && _.isEqual('no', this.previousInspectionDetail?.components[component.componentID].languageKeys[0])
          },
          component
        ]
      });
    }
  }

  addImageIfRadioButtonNotAvailable = (component, inspectionComponent: IComponentModel, configComponent) => {
    if(inspectionComponent.booleanValues.length > 0 && inspectionComponent.booleanValues[0]) {
      component.type = ComponentTypes.normal;
      component.values.push(this.config.languages[this.selectedLanguage][configComponent.input_values.radio_button_values[0]] || configComponent.input_values.radio_button_values[0])

      if(this.unavailableArray.includes(configComponent.input_values.radio_button_values[0])) {
        component['unavailable'] = true;
      }

    } else {
      component.type = ComponentTypes.carousel;
      inspectionComponent.urlValues.map((url, index) => {
        component.values[index] = this.config.languages[this.selectedLanguage][inspectionComponent.componentName] || inspectionComponent.componentName;
        component.urls[index] = url;
      })
    }
  }

  addFreeTextIfNoLanguageKey = (component, inspectionComponent: IComponentModel) => {
    component.values[0] = (inspectionComponent.languageKeys.length > 0 && inspectionComponent.languageKeys[0] != 'other' && inspectionComponent.languageKeys[0] != 'others') ? this.config.languages[this.selectedLanguage][inspectionComponent.languageKeys[0]] : inspectionComponent.textValues[0];
  }

  addImageIfYesRadioButton = (component, inspectionComponent: IComponentModel) => {
    if(inspectionComponent.languageKeys[0] == 'no') {
      component.type = ComponentTypes.normal;
      component.values.push(this.config.languages[this.selectedLanguage]['no']);
      component['unavailable'] = true;
    } else {
      component.type = ComponentTypes.carousel;
      inspectionComponent.urlValues.map((url, index) => {
        component.values[index] = this.config.languages[this.selectedLanguage][inspectionComponent.componentName] || inspectionComponent.componentName;
        component.urls[index] = url;
      })
    }
  }

  addRadioButtonCheckBox = (component, inspectionComponent: IComponentModel, configComponent) => {
    if(inspectionComponent.booleanValues.length > 0 && inspectionComponent.booleanValues[0]) {
      component.values.push(this.config.languages[this.selectedLanguage][configComponent.input_values.checkbox_values[0]] || configComponent.input_values.checkbox_values[0])
      component['specialCheck'] = true;
      if(this.unavailableArray.includes(configComponent.input_values.checkbox_values[0])) {
        component['unavailable'] = true;
      }

    } else {
      component.values = this.config.languages[this.selectedLanguage][inspectionComponent.languageKeys[0]] || inspectionComponent.languageKeys[0];
    }
  }

  addExtensionDevices = (parentComponent, parentInspectionComponent: IComponentModel, isPerviousInspection:boolean) => {
    if(parentInspectionComponent.languageKeys[0] === "yes") {
      let extensionDevicesObject = {};
      extensionDevicesObject['0'] = [];
      parentInspectionComponent.textValues.map((componentID) => {
        let computedComponentID;
        let inspectionComponent;
        let extensionDeviceID;

        if(!componentID.includes('_')) {
          computedComponentID = componentID;
          inspectionComponent = isPerviousInspection ? this.previousInspectionDetail.components[componentID] : this.inspectionDetail.components[componentID];
          extensionDeviceID = 0;
        } else {
          extensionDeviceID = componentID.substring(componentID.indexOf('_') + 1);
          computedComponentID = componentID.substring(0, componentID.indexOf('_'));
          if(!extensionDevicesObject[extensionDeviceID]) {
            extensionDevicesObject[extensionDeviceID] = [];
          }
          inspectionComponent = isPerviousInspection ? this.previousInspectionDetail.components[componentID]:  this.inspectionDetail.components[componentID];
        }

        if(inspectionComponent) {
          const configComponent = this.config.machines[this.machineCategory].components[computedComponentID];
          if(configComponent.main_category == this.section.title) {
            let subComponent = {
              "componentID": componentID,
              "width": 49.7,
              "perspective": configComponent.perspective,
              "type": ComponentTypes.normal,
              "title":  this.config.languages[this.selectedLanguage][configComponent.name],
              "values": [],
              "urls": [],
              "unit": ""
            }
            this.computeInspectionValues(subComponent, inspectionComponent, configComponent, isPerviousInspection);
            if(subComponent.values.length && subComponent.values[0]) {
              extensionDevicesObject[extensionDeviceID].push(subComponent);
            }
          }
        }
      });

      Object.keys(extensionDevicesObject).map((extensionDeviceKey) => {
        if(extensionDevicesObject[extensionDeviceKey].length) {
          if(isPerviousInspection) {
            let columnIndex = 0;
            const deviceIndex = this.previousSection.columns[columnIndex].subCategories.length;
            this.previousSection.columns[columnIndex].subCategories.push({
              "title": this.config.languages[this.selectedLanguage]['extension_device'] + ' ' + deviceIndex,
              "components": extensionDevicesObject[extensionDeviceKey],
              "isVisible": true
            });
            // columnIndex = columnIndex === 0 ? 1 : 0
          } else {
            let columnIndex = 0;
            const deviceIndex = this.section.columns[columnIndex].subCategories.length;
            this.section.columns[columnIndex].subCategories.push({
              "title": this.config.languages[this.selectedLanguage]['extension_device'] + ' ' + deviceIndex,
              "components": extensionDevicesObject[extensionDeviceKey],
              "isVisible": true
            });
            // columnIndex = columnIndex === 0 ? 1 : 0
          }
        }
      });
    }
  }

  insertMultipleBooleanSubComponents = (section, componentsToInsertArray) => {
    componentsToInsertArray.map(component => {
      section.columns[component.columnIndex].subCategories[component.categoryIndex]['isVisible'] = true;
      let componentsArray = section.columns[component.columnIndex].subCategories[component.categoryIndex].components;
      const componentIndex = componentsArray.findIndex(comp => comp.componentID == component.componentID);
      componentsArray.splice(componentIndex, 1 , ...component.subComponents)
    });
  }

  isUrl = (s) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
 }
}
