import { InspectionTypes } from './../shared/enums/inspection-types.enum';
import { InspectionStates } from 'src/app/shared/enums/inspection-states.enum';
import { CallAPIService } from '../core/services/call-api.service';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class InspectionsService {
  inspectionsUrl: string = 'inspections/';

  constructor(
    private callAPIService: CallAPIService
  ) {}

  getIK = (organizationIds) => {
   return this.callAPIService.callGetAPI(this.inspectionsUrl, {organizationIds , type: InspectionTypes.IK, states: [InspectionStates.INSPECTION_DONE, InspectionStates.REOPENED, InspectionStates.RATING_DONE]});
  }

  getIE = (organizationIds) => {
    return this.callAPIService.callGetAPI(this.inspectionsUrl, {organizationIds , type: InspectionTypes.IE, states: [InspectionStates.INSPECTION_DONE, InspectionStates.PAUSED, InspectionStates.NEW]});
  }

  getIV = (organizationIds) => {
    return this.callAPIService.callGetAPI(this.inspectionsUrl, {organizationIds , type: InspectionTypes.IV, states: [InspectionStates.INSPECTION_DONE, InspectionStates.NEW, InspectionStates.REOPENED]});
  }

  getIKDetail = (inspectionID) => {
    return this.callAPIService.callGetAPI(this.inspectionsUrl + inspectionID, {type: InspectionTypes.IK});
  }

  getIEDetail = (inspectionID) => {
    return this.callAPIService.callGetAPI(this.inspectionsUrl + inspectionID, {type: InspectionTypes.IE});
  }

  getIVDetail = (inspectionID) => {
    return this.callAPIService.callGetAPI(this.inspectionsUrl + inspectionID, {type: InspectionTypes.IV});
  }

  updateInspectionState = (inspectionID, payLoad) => {
    return this.callAPIService.callPutAPI(this.inspectionsUrl + inspectionID, '' , payLoad);
  }

  getInspectionOffspring = (inspectionID) => {
    return this.callAPIService.callGetAPI(this.inspectionsUrl + inspectionID + '/offspring');
  }

  getCSVFile = (date: string) => {
    return this.callAPIService.callGetCSVAPI(this.inspectionsUrl + 'export', {date});
  }
}
