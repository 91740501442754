<div fxFlex="column" class="input-dialog dialog" [formGroup]="inputDialogForm">

  <div class="dialog-header">
    {{ title }}
  </div>

  <div class="dialog-container" fxLayout="column">

    <div class="dialog-body">
      {{ text }}

      <mat-form-field class="comment-input" hideRequiredMarker>
        <mat-label>{{ 'dialogs.ik-cancel.comments' | translate}}</mat-label>
        <textarea matInput rows="1" mat-autosize="true" formControlName="textAreaControl" maxlength="500"></textarea>
      </mat-form-field>
    </div>

    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="space-between">
      <button class="btn btn-grey" mat-button type="button" (click)="onCancel()">
        {{ cancelBtn || 'cancel' | translate }}
      </button>
      <button class="btn btn-primary" mat-button type="button" (click)="onConfirm()" [disabled]="inputDialogForm.invalid">
        {{ confirmBtn || 'yes' | translate }}
      </button>
    </div>
  </div>
</div>
