export enum InspectionStates {
  UNDEFINED = 0,
  NEW = 1,
  INSPECTION_DONE = 2,
  REOPENED = 3,
  RATING_DONE = 4,
  WON = 5,
  LOST = 6,
  PAUSED = 7,
  SOLD = 8,
}
