import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  confirmSelected = new EventEmitter<boolean>();
  cancelSelected = new EventEmitter<boolean>();
  title: string;
  text: string;
  cancelBtn: string = null;
  confirmBtn: string = null;
  hideStaticText: boolean = false;
  hideCancelBtn: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.title = data.title;
    this.text = data.text;
    this.cancelBtn = data.cancelBtn;
    this.confirmBtn = data.confirmBtn;
    this.hideStaticText = data.hideStaticText,
    this.hideCancelBtn = data.hideCancelBtn
  }

  ngOnInit(): void {
  }

  onConfirm = () => {
    this.confirmSelected.emit(true);
    this.dialogRef.close();
  };

  onCancel = () => {
    this.cancelSelected.emit(true);
    this.dialogRef.close();
  };

}
