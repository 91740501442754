import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  environment = environment;
  currentLanguage = 'de';

  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit() {}

  toggleLanguage = () => {
    const language = this.currentLanguage === 'de' ? 'en' : 'de';
    this.translateService.use(language);
    this.currentLanguage = language;
  }
}
