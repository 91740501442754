import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() slides: string[];
  @Input() imageTitles?: string[];
  @Input() imageDescriptions?: string[];
  @Input() section?: string;
  @Input() isMachinePictures?: boolean;

  @ViewChild("container") container: ElementRef;
  @ViewChild("imageElement") imageElement: ElementRef;

  currentSlide: number = 0;

  fullScreenMode: boolean = false;

  pos = { x: 0, y: 0 };
  target = { x: 0, y: 0 };
  pointer = { x: 0, y: 0 };
  size = { 
    w: 0, 
    h: 0
  };
  scale = 1;
  speed = 0.5;
  mouse = {x: 0, y: 0, oldX: 0, oldY: 0, button: false};

  constructor() { }

  ngOnInit(): void {
  }

  onPreviousClick = () => {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick = () => {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }

  onNavigatorClick = (index: number) => {
    this.currentSlide = index;
  }

  onFullScreenMode = () => {
    this.fullScreenMode = true;
    this.size = {
      w: this.container.nativeElement.offsetWidth,
      h: this.container.nativeElement.offsetHeight
    }
    addEventListener('mousemove', this.onMouseDown.bind(this));
    addEventListener('mousedown', this.onMouseDown.bind(this));
    addEventListener('mouseup', this.onMouseDown.bind(this));
    addEventListener('mouseout', this.onMouseDown.bind(this));
    addEventListener('wheel', this.onMouseWheel.bind(this), {passive: false});
  }

  onNormalMode = () => {    
    this.resetZoom();
    removeEventListener('mousemove', this.onMouseDown);
    removeEventListener('mousedown', this.onMouseDown);
    removeEventListener('mouseup', this.onMouseDown);
    removeEventListener('mouseout', this.onMouseDown);
    removeEventListener('wheel', this.onMouseWheel);
    this.fullScreenMode = false;
  }

  onMouseDown(event: MouseEvent) {
    if (event.target === this.imageElement.nativeElement && this.scale > 1) {
      if (event.type === "mousedown") { this.mouse.button = true }
      if (event.type === "mouseup" || event.type === "mouseout") { this.mouse.button = false }
      this.mouse.oldX = this.mouse.x;
      this.mouse.oldY = this.mouse.y;
      this.mouse.x = event.pageX;
      this.mouse.y = event.pageY;
      if(this.mouse.button) {
        this.pos.x += this.mouse.x - this.mouse.oldX;
        this.pos.y += this.mouse.y - this.mouse.oldY;
        
        (event.target as HTMLImageElement).style.transform = `translate(${this.pos.x }px,${this.pos.y }px) scale(${this.scale},${this.scale})`;
      }
      event.preventDefault();
    }
  }

  onMouseWheel(event: WheelEvent) {
    if (event.ctrlKey) {
      event.preventDefault();
      const imageTarget = event.target as HTMLImageElement;
      if (imageTarget.classList.contains("slide")) {
        this.pointer.x = event.pageX - imageTarget.parentElement.offsetLeft;
        this.pointer.y = event.pageY - imageTarget.offsetTop;
        this.target.x = (this.pointer.x - this.pos.x) / this.scale;
        this.target.y = (this.pointer.y - this.pos.y) / this.scale;

        this.scale +=
          -1 *
          Math.max(-1, Math.min(1, event.deltaY)) *
          this.speed *
          this.scale;

        const max_scale = 3;
        const min_scale = 1;
        this.scale = Math.max(min_scale, Math.min(max_scale, this.scale));

        this.pos.x = this.target.x * this.scale + this.pointer.x;
        this.pos.y = this.target.y * this.scale + this.pointer.y;

        if (this.pos.x > 0) this.pos.x = 0;
        if (this.pos.x + this.size.w * this.scale < this.size.w)
          this.pos.x = -this.size.w * (this.scale - 1);
        if (this.pos.y > 0) this.pos.y = 0;
        if (this.pos.y + this.size.h * this.scale < this.size.h)
          this.pos.y = -this.size.h * (this.scale - 1);

        imageTarget.style.transform = `translate(${this.pos.x}px,${this.pos.y}px) scale(${this.scale},${this.scale})`;
      }
    }
  }
  resetZoom() {
    this.imageElement.nativeElement.style.transform = "";
    this.pos = { x: 0, y: 0 };
    this.target = { x: 0, y: 0 };
    this.pointer = { x: 0, y: 0 };
    this.scale = 1;
    this.size = {
      w: 0,
      h: 0,
    };
    this.mouse = {x: 0, y: 0, oldX: 0, oldY: 0, button: false};
    this.imageElement.nativeElement.style.transform = "";
  }
}
