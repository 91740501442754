<div fxFlex="column" class="forgot-password dialog" [formGroup]="forgotPasswordForm">

  <div class="dialog-header">
    {{ 'dialogs.forgot-password.header' | translate }}
  </div>

  <div class="dialog-container">
    <div class="dialog-body" fxLayout="column">
    {{ 'dialogs.forgot-password.body' | translate }}

      <mat-form-field class="email-input" hideRequiredMarker>
        <mat-label>{{'email' | translate}}</mat-label>
        <input matInput formControlName="email" type="email" required email>
      </mat-form-field>
    </div>

    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="space-between">
      <div>
        <button class="btn btn-grey" mat-button type="button" (click)="onCancel()">
          {{ 'cancel' | translate }}
        </button>
      </div>
      <div>
        <button class="btn btn-primary" [disabled]="forgotPasswordForm.status === 'INVALID'" mat-button type="button" (click)="onSubmit()">
          {{ 'request' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
