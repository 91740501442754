<div class="filter-container" [ngClass]="{'users': route == 'users'}">
  <mat-icon class="material-icons filter-icon pr-20" mat-button [matMenuTriggerFor]="filterMenu"
    #filter="matMenuTrigger" [ngClass]="{'filter-icon-active' : filter.menuOpen}">
    filter_alt
  </mat-icon>
  <mat-menu class="filter-menu" #filterMenu="matMenu">
    <div class="checkbox-container">
      <mat-checkbox class="filter-cb" (click)="$event.stopPropagation();" [checked]="allComplete"
        [indeterminate]="someComplete()" (change)="setAll($event.checked)">
        {{inspectionStatusFilter.name}}
      </mat-checkbox>
      <div *ngFor="let filter of inspectionStatusFilter.subFilters">
        <mat-checkbox *ngIf="filter.display" class="sub-filter-cb" (click)="$event.stopPropagation();" [(ngModel)]="filter.checked"
          (ngModelChange)="updateAllComplete(); filterChanged()">
          {{filter.name}}
        </mat-checkbox>
      </div>
    </div>
  </mat-menu>
</div>
