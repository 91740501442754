import { UserRoles } from "../shared/enums/user-roles.enum";
import { Router } from "@angular/router";
import { StorageService } from "../core/services/storage.service";
import { User } from "../shared/models/user.model";
import {
  ILoginModel,
  IUser,
  IForgotPasswordModel,
} from "../shared/interfaces";
import {
  HttpClient,
  HttpHeaders
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppInitService } from "../app.init";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private user = new BehaviorSubject<User>(null);
  currentUser = this.user.asObservable();
  isAdmin: boolean = false;
  isUS: boolean = false;
  loginTypeAdmin: boolean; // true means Admin and false means US

  baseUrl: string;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private appInitService: AppInitService
  ) {
    this.baseUrl = this.appInitService.getConfig().api_url;
    this.autoLogin();
  }

  login(email: string, password: string, platform: number, captcha?: string): Observable<any> {
    this.setLoginType(platform);
    const url: string = this.baseUrl + "account";

    const body: ILoginModel = {
      password: password,
      email: email,
      platform,
      itemid: "",
      type: 0,
      captcha: captcha
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    return this.http
      .post<IUser>(url, body, { headers })
      .pipe(
        map((response) => {
          if (response.detail) {
            this.setUser(response);
            this.storeUserData(response);
            return response;
          } else {
            return null;
          }
        })
      );
  }

  setLoginType = (platform: number) => {
    platform == 2 ? this.storageService.setLoginTypeAdmin(false) : this.storageService.setLoginTypeAdmin(true);
    platform == 2 ? this.loginTypeAdmin = false : this.loginTypeAdmin = true;
  }

  storeUserData = (response: IUser) => {
    this.storageService.setUserData(response);
  };

  setUser = (response: IUser) => {
    const user = new User(
      response.detail.itemid,
      response.detail.token,
      response.detail.type,
      response.detail.userModel
    );
    this.user.next(user);
    this.isAdmin = response.detail.userModel.role == UserRoles.Admin ? true : false;
    this.isUS = response.detail.userModel.role == UserRoles.US ? true : false;
  };

  autoLogin() {
    const userData: IUser = this.storageService.getUserData();
    if (!userData || (!userData.detail.userModel.agbsAccepted && userData.detail.userModel.role == UserRoles.US)) {
      return;
    }
    this.setUser(userData);
    this.loginTypeAdmin = this.storageService.getLoginTypeAdmin();
  }

  isAuthenticated = () => {
    return !!this.user.value;
  };

  getToken = () => {
    return this.user.value.token;
  };

  // getAGBContent = () => {
  //   let url: string = this.baseUrl + "files/agbs/de/terms-of-service.html";

  //   return this.http.get<any>(url, { responseType: "document" } as any);
  // };

  acceptAGBContent = () => {
    let url: string = this.baseUrl + "agbs/accept";

    return this.http.put<any>(url, {}).pipe(
      map((response) => {
        if (response.status == 200) {
          let tempUser = JSON.parse(JSON.stringify(this.user.value));
          tempUser.userModel.agbsAccepted = true;
          const token = this.user.value.token;

          const loadedUser = new User(
            tempUser.itemid,
            token,
            tempUser.type,
            tempUser.userModel
          );
          this.user.next(loadedUser);
          this.router.navigate(['inspections/ik']);
        }
        return response;
      })
    );
  };

  forgotPassword = (email: string) => {
    let url: string = this.baseUrl + "account/password/reset";

    let body: IForgotPasswordModel = {
      email: email,
      itemid: "",
      type: 6,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    return this.http.put<any>(url, body, { headers });
  };

  logout = () => {
    let url: string =
      this.baseUrl + "account";
    return this.http.delete<any>(url);
  };

  deleteUserData = () => {
    this.router.navigate(["login"]);
    this.storageService.removeUserData();
    this.user.next(null);
    this.isAdmin = false;
    this.isUS = false;
  };

  resetPassword = (payLoad) => {
    const url = this.baseUrl + "account/password/update";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    return this.http.put<any>(url, payLoad, { headers });
  }
}
