import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  confirmSelected = new EventEmitter<string>();
  cancelSelected = new EventEmitter<boolean>();
  title: string;
  text: string;
  cancelBtn: string = null;
  confirmBtn: string = null;

  inputDialogForm: FormGroup = new FormGroup({
    textAreaControl: new FormControl("", [Validators.required, Validators.maxLength(500)])
  });

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.title = data.title;
    this.text = data.text;
    this.cancelBtn = data.cancelBtn;
    this.confirmBtn = data.confirmBtn;
  }

  ngOnInit(): void {
  }

  onConfirm = () => {
    this.confirmSelected.emit(this.inputDialogForm.controls['textAreaControl'].value);
    this.dialogRef.close();
  };

  onCancel = () => {
    this.cancelSelected.emit(true);
    this.dialogRef.close();
  };

}
