import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { AppInitService } from "src/app/app.init";

@Injectable({
  providedIn: "root",
})
export class CallAPIService {
  private baseUrl: string;

  public headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private appInitService: AppInitService
    ) {
      this.baseUrl = this.appInitService.getConfig().api_url;
    }

  public setHeaders() {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
    });
  }
  public setImageHeaders(token) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/zip",
      "Accept": "application/zip",
      "Authorization": token,
    });
  }
  public setPDFHeaders(token) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/pdf",
      "Accept": "application/pdf",
      "Authorization": token,
    });
  }

  public callGetAPI(url, paramsObject?) {
    this.setHeaders();
    const params = this.getHttpParams(paramsObject);
    return this.http
      .get(this.baseUrl + url, { headers: this.headers, params })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public callPostAPI(url, data) {
    this.setHeaders();
    return this.http
      .post(this.baseUrl + url, data, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public callDeleteAPI(url) {
    this.setHeaders();
    return this.http.delete(this.baseUrl + url, { headers: this.headers }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public callPutAPI(url, paramsObject?, data?) {
    this.setHeaders();
    const params = this.getHttpParams(paramsObject);
    return this.http
      .put(this.baseUrl + url, data, { headers: this.headers, params })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }


  public callGetCSVAPI(url, paramsObject?) {
    let headers = new HttpHeaders({
      Accept: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet'
    });

    const params = this.getHttpParams(paramsObject);

    return this.http
      .get(this.baseUrl + url, { headers, params, responseType: "arrayBuffer" as any })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public callGetImagesAPI(url, token) {
    this.setImageHeaders(token);
    return this.http
      .get(this.baseUrl + url, { headers: this.headers, responseType: 'arraybuffer'});
  }
  public callGetPDFAPI(url, token) {
    this.setPDFHeaders(token);
    return this.http
      .get(this.baseUrl + url, { headers: this.headers, responseType: 'blob', observe: 'response'});
  }

  private getHttpParams(object?: any): HttpParams {
    let params: HttpParams = new HttpParams();
    if (object) {
      Object.keys(object).map((key) => {
        params = params.set(key, object[key]);
      });
    }
    return params;
  }
}
