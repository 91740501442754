import { StorageService } from '../../core/services/storage.service';
import { InspectionTypes } from '../enums/inspection-types.enum';
import { UserRoles } from '../enums/user-roles.enum';
import { InspectionStates } from '../enums/inspection-states.enum';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {IFilterModel, IInspectionModel, IPortalUser} from '../interfaces';


@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private filterValue = new BehaviorSubject<IFilterModel>(null);
  currentFilterValue = this.filterValue.asObservable();
  private filterObject = {
      history: {
        name: 'Alle auswählen',
        subFilters: [
          {
            name: 'Begutachtung',
            checked: true,
            display: true
          },
          {
            name: 'Eingangskontrolle',
            checked: true,
            display: true
          },
          {
            name: 'Verkaufsvorbereitung',
            checked: true,
            display: true
          },
          {
            name: 'WON',
            checked: true,
            display: true
          },
          {
            name: 'LOST',
            checked: true,
            display: true
          },
          {
            name: 'Verkauft',
            checked: true,
            display: true
          }
        ],
      },
      users: {
        name: 'Alle auswählen',
        subFilters: [
          {
            name: 'Admin',
            checked: true,
            display: true
          },
          {
            name: 'US',
            checked: true,
            display: true
          },
          {
            name: 'KI',
            checked: true,
            display: true
          },
          {
            name: 'NI',
            checked: true,
            display: true
          },
          {
            name: 'Active',
            checked: true,
            display: true
          },
          {
            name: 'Inactive',
            checked: true,
            display: true
          }
        ],
      },
      ik: {
        name: 'Alle auswählen',
        subFilters: [
          {
            name: 'Maschine aufgenommen',
            checked: true,
            display: true
          },
          {
            name: 'Pausiert',
            checked: false,
            display: false
          },
          {
            name: 'Neu',
            checked: false,
            display: false
          },
          {
            name: 'Wieder geöffnet',
            checked: false,
            display: true
          },
          {
            name: 'Bewertung abgeschlossen',
            checked: false,
            display: true
          }
        ],
      },
      ie: {
        name: 'Alle auswählen',
        subFilters: [
          {
            name: 'Maschine aufgenommen',
            checked: true,
            display: true
          },
          {
            name: 'Pausiert',
            checked: true,
            display: true
          },
          {
            name: 'Neu',
            checked: false,
            display: true
          },
          {
            name: 'Wieder geöffnet',
            checked: false,
            display: false
          },
          {
            name: 'Bewertung abgeschlossen',
            checked: false,
            display: false
          }
        ],
      },
      iv: {
        name: 'Alle auswählen',
        subFilters: [
          {
            name: 'Maschine aufgenommen',
            checked: true,
            display: true
          },
          {
            name: 'Pausiert',
            checked: false,
            display: false
          },
          {
            name: 'Neu',
            checked: false,
            display: true
          },
          {
            name: 'Wieder geöffnet',
            checked: false,
            display: true
          },
          {
            name: 'Bewertung abgeschlossen',
            checked: false,
            display: false
          }
        ],
      }
    };

  constructor(private storageService: StorageService) {
    this.setFilterObject();
  }

  setFilterObject = () => {
    const selectedFilter = this.storageService.getJSONItem('selectedFilter');
    if (selectedFilter) {
      this.filterObject = selectedFilter;
    } else {
      this.storageService.setJSONItem('selectedFilter', this.filterObject);
    }
    this.filterValue.next(this.filterObject);
  }

  setFilterValue = (singleFilterValue, route) => {
    this.filterObject[route] = singleFilterValue;
    this.storageService.setJSONItem('selectedFilter', this.filterObject);
    this.filterValue.next(this.filterObject);
  }

  getFilerValue = (route) => {
    return this.filterObject[route];
  }

  filterIKIEIV = (inspectionsArray: IInspectionModel[], route) => {
    const routeFilter = this.filterValue.value[route];
    return inspectionsArray.filter(inspection =>
      (routeFilter.subFilters[0].checked && inspection.inspectionState === InspectionStates.INSPECTION_DONE)
      || (routeFilter.subFilters[1].checked && inspection.inspectionState === InspectionStates.PAUSED)
      || (routeFilter.subFilters[2].checked && inspection.inspectionState === InspectionStates.NEW)
      || (routeFilter.subFilters[3].checked && inspection.inspectionState === InspectionStates.REOPENED)
      || (routeFilter.subFilters[4].checked && inspection.inspectionState === InspectionStates.RATING_DONE)
    );
  }

  filterHistory = (inspectionsArray: IInspectionModel[]) => {
    const historyFilter = this.filterValue.value.history;
    return inspectionsArray.filter(inspection => {
      if (!historyFilter.subFilters[3].checked && !historyFilter.subFilters[4].checked && !historyFilter.subFilters[5].checked) {
        return (historyFilter.subFilters[0].checked && inspection.inspectionType === InspectionTypes.IK)
        || (historyFilter.subFilters[1].checked && inspection.inspectionType === InspectionTypes.IE)
        || (historyFilter.subFilters[2].checked && inspection.inspectionType === InspectionTypes.IV);
      } else if (!historyFilter.subFilters[0].checked && !historyFilter.subFilters[1].checked && !historyFilter.subFilters[2].checked) {
        return (historyFilter.subFilters[3].checked && inspection.inspectionState === InspectionStates.WON)
        || (historyFilter.subFilters[4].checked && inspection.inspectionState === InspectionStates.LOST)
        || (historyFilter.subFilters[5].checked && inspection.inspectionState === InspectionStates.SOLD);
      } else {
        return ( ((historyFilter.subFilters[0].checked && inspection.inspectionType === InspectionTypes.IK)
        || (historyFilter.subFilters[1].checked && inspection.inspectionType === InspectionTypes.IE)
        || (historyFilter.subFilters[2].checked && inspection.inspectionType === InspectionTypes.IV))
        &&
        ((historyFilter.subFilters[3].checked && inspection.inspectionState === InspectionStates.WON)
        || (historyFilter.subFilters[4].checked && inspection.inspectionState === InspectionStates.LOST)
        || (historyFilter.subFilters[5].checked && inspection.inspectionState === InspectionStates.SOLD)));
      }
    }
    );
  }

  filterUsers = (usersArray: IPortalUser[]) => {
    const usersFilter = this.filterValue.value.users;
    return usersArray.filter(user =>
      (usersFilter.subFilters[0].checked && user.role === UserRoles.Admin)
      || (usersFilter.subFilters[1].checked && user.role === UserRoles.US)
      || (usersFilter.subFilters[2].checked && user.role === UserRoles.KI)
      || (usersFilter.subFilters[3].checked && user.role === UserRoles.NI)
      || (usersFilter.subFilters[4].checked && user.activated === true)
      || (usersFilter.subFilters[5].checked && user.activated === false)
    );
  }

}
