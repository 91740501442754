import { USGuard } from './core/guards/us.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';


const routes: Routes = [
  {path: 'home', pathMatch: 'full', component: HomeComponent, canActivate: [AuthGuard]},
  {path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canLoad: [AdminGuard]},
  {path: 'history', loadChildren: () => import('./history/history.module').then(m => m.HistoryModule), canLoad: [USGuard]},
  {path: 'history-list', loadChildren: () => import('./history-list/history-list.module').then(m => m.HistoryListModule),
    canLoad: [USGuard]},
  {path: 'inspections', loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule), canLoad: [USGuard]},
  {path: '**', pathMatch: 'full', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
