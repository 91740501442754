import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {

  private storage = localStorage;

  getUserData() {
    return JSON.parse(this.storage.getItem('userDataWNUM'));
  }

  setUserData(userData) {
    this.storage.setItem('userDataWNUM', JSON.stringify(userData));
  }

  removeUserData() {
    this.storage.removeItem('userDataWNUM');
    this.removeItem('selectedOrg');
    this.removeItem('selectedFilter');
  }

  setLoginTypeAdmin(isAdmin: boolean) {
    localStorage.setItem('isAdmin', isAdmin.toString());
  }

  getLoginTypeAdmin() {
    return JSON.parse(localStorage.getItem('isAdmin'));
  }

  getConfigs() {
    return JSON.parse(localStorage.getItem('configs'));
  }

  setConfigs(configs) {
    localStorage.setItem('configs', JSON.stringify(configs));
  }

  getJSONItem = (key) => {
    return JSON.parse(localStorage.getItem(key))
  }

  setJSONItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem = (key) => {
    return localStorage.getItem(key);
  }

  setItem = (key, value) => {
    localStorage.setItem(key, value);
  }

  removeItem = (key) => {
    localStorage.removeItem(key);
  }
}
