<div #container class="carousel" [ngClass]="fullScreenMode ? 'full-screen' : 'normal'" [class.machine] = "isMachinePictures">
  <mat-icon class="cancel-icon" *ngIf="fullScreenMode" (click)="onNormalMode()">
    cancel
  </mat-icon>
  <div *ngFor="let slide of slides; let i = index" class="image-container">
    <div class="background-img" [ngClass]="{'hidden': i != currentSlide}">
    </div>
    <img #imageElement
      [ngClass]="{'machine-pic': isMachinePictures, 'component-pic': !isMachinePictures, 'hidden': i != currentSlide}"
      [src]="(slide | secure) | async"
      class="slide"
      (click)="onFullScreenMode()"
    />
    <div *ngIf="imageTitles?.length > 0" [hidden]="i !== currentSlide" class="image-title">
      {{ imageTitles[0] }}
    </div>
  </div>

  <button *ngIf="slides?.length > 1" class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button *ngIf="slides?.length > 1" class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>

  <div *ngIf="slides?.length > 1" class="navigator-container" [ngClass]="{'above': imageTitles?.length > 0}">
    <div *ngFor="let slide of slides; let i = index" class="navigator" [ngClass]="{'active': i === currentSlide}" (click)="onNavigatorClick(i)">
    </div>
  </div>
</div>

<div *ngIf="section == 'damages' || (section == 'addon' && imageDescriptions?.length) " class="component">
  <div class="component__title" fxLayout="row" fxLayoutAlign="space-between center">
    {{ 'description' | translate }}
  </div>
  <div class="component__value">
    {{ imageDescriptions[currentSlide] || '-' }}
  </div>
</div>

