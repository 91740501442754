import { UserRoles } from './../../shared/enums/user-roles.enum';
import { ForgotPasswordComponent } from './../forgot-password/forgot-password.component';
import { AuthService } from "../auth.service";
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IUser } from 'src/app/shared/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { AgbComponent } from '../agb/agb.component';
import "friendly-challenge/widget";
import { WidgetInstance } from 'friendly-challenge';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild('frccaptcha', { static: false }) 
  friendlyCaptcha: ElementRef<HTMLElement>; 
  showPassword: boolean = false;
  isAdminMode: boolean;
  loginForm: FormGroup = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$"),
    ]),
    password: new FormControl("", [Validators.required]),
  });
  loginSuccessful: boolean = false;
  returnUrl: string;
  capsLockOn: boolean;
  captchaNeeded: boolean;
  captcha: string;
  widget: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    ) {}

  ngOnInit(): void {
    this.isAdminMode = this.authService.loginTypeAdmin;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'inspections/ik';
    this.captchaNeeded = false;
  }

  isCapsLockOn = (event) => {
    typeof event.getModifierState === 'function' && event.getModifierState("CapsLock") ? this.capsLockOn = true : this.capsLockOn = false;
  }

  togglePasswordVisibility = () => {
    this.showPassword = !this.showPassword;
  };

  setUserType(userType: string) {
    this.isAdminMode = userType === UserRoles.Admin ? true : false;
  }

  onLogin = () => {
    this.authService
      .login(
        this.loginForm.value.email,
        this.loginForm.value.password,
        this.isAdminMode ? 3 : 2,
        this.captcha ? this.captcha : null
      )
      .subscribe((response: IUser) => {
        if(response) {
          if(!this.isAdminMode && !response.detail.userModel.agbsAccepted) {
            this.openAGBDialog();
          } else {
            this.loginSuccessful = true;
            this.isAdminMode ? this.router.navigate(['users']) : this.router.navigate([this.returnUrl]);
          }
        }
      }, (error) => {
        if (error === 401 && !this.captcha) {
          return;
        } else {
          this.captchaNeeded = true;
          this.widget = new WidgetInstance(this.friendlyCaptcha.nativeElement, {
            doneCallback: (captcha) => {
              this.captchaNeeded = false;
              this.captcha = captcha;
            },
            errorCallback: (b) => {
              // console.log('FAILED', b);
            },
          })
    
         this.widget.start()
        }
      });
  };

  openAGBDialog = () => {
    this.dialog.open(AgbComponent, {
      data: {
        // agbContent
      },
      autoFocus: false,
      maxWidth: '90%',
      disableClose: true
    });
  }

  onForgotPassword = () => {
    this.openForgotPasswordDialog();
  }

  openForgotPasswordDialog = () => {
    this.dialog.open(ForgotPasswordComponent, {
      autoFocus: false,
      maxWidth: '90%',
    })
  }
}
