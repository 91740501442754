import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CallAPIService } from './call-api.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public static readonly TOKEN_KEY = 'userDataWNUM';

  constructor(private callApiService: CallAPIService) {}

  /* ***** Public Methods ****** */

  public static getDefaultHeaders(token?: string): HttpHeaders {
    return new HttpHeaders({
      accept: 'application/json',
      Authorization: HelperService.getToken(token),
    });
  }

  public static getHttpParams(object?: any): HttpParams {
    let params: HttpParams = new HttpParams();
    if (object) {
      Object.keys(object).forEach((key) => {
        params = params.set(key, object[key]);
      });
    }
    return params;
  }

  /* ***** Private Methods ****** */

  private static getToken(token?: string) {
    if (token === undefined) {
      return (
        'Bearer ' +
        JSON.parse(window.localStorage.getItem(HelperService.TOKEN_KEY)).detail
          .token
      );
    } else {
      return token;
    }
  }

  parseGermanDate = (date: Date) => {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '.' + mm + '.' + yyyy;
  }

  downloadImages = (inspectionId: number) => {
    this.callApiService.callGetImagesAPI("files/images/zip/" + inspectionId, HelperService.getToken()).subscribe((data) => {
      const blob = new Blob([data], {
        type: 'application/zip'
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    })
  }
  getPDF = (inspectionId: number, language: string, internal?: boolean) => {
    this.callApiService.callGetPDFAPI("inspections/export/pdf/" + inspectionId + "?usage=" + (internal ? "internal" : "external") + "&lang="+language, HelperService.getToken()).subscribe((response) => {
      const blob = new Blob([response.body], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const fileName = response.headers.get("content-disposition").split('filename="')[1].replace('"', '');
      window.open(url, '_blank');
    })
  }
}
