import { AgbComponent } from './agb/agb.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from '../core/guards/logged-in.guard';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent, canActivate: [LoggedInGuard] },
  { path: 'password/:emailToken', component: ResetPasswordComponent, canActivate: [LoggedInGuard] },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AuthRoutingModule {
  static components = [ LoginComponent, AgbComponent, ForgotPasswordComponent, ResetPasswordComponent ];
}
