import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate():
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
      return this.authService.currentUser.pipe(
        take(1),
        map(user => {
          const isNotAuth = !user;
          if (isNotAuth) {
            return true;
          }
          if(this.authService.isAdmin) {
            return this.router.createUrlTree(['users']);
          } else {
            return this.router.createUrlTree(['inspections/ik']);
          }
          return false;
        })
      )
  }
}
