import { StorageService } from './../../core/services/storage.service';
import { User } from './../models/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IOrganizationModel } from '../interfaces';

@Injectable({
  providedIn: "root",
})
export class SelectOrgService {
  private selectedValue = new BehaviorSubject<string[]>([]);
  currentSelectedValue = this.selectedValue.asObservable();
  organizationList: IOrganizationModel[];

  constructor(
    private authService:AuthService,
    private storageService: StorageService
  ) {
    this.authService.currentUser.subscribe((user: User) => {
      if(user) {
        this.organizationList = user.userModel.organizations;
        let selectedOrganizations = [];
        this.organizationList.map(organization => {
          if(organization.isLowestOrganization) selectedOrganizations.push(organization.itemid);
        });

        const selectedOrg = storageService.getJSONItem('selectedOrg');
        selectedOrg ? this.selectedValue.next(selectedOrg) : this.selectedValue.next(selectedOrganizations);
      }
    })
  }

  setSelectedValue = (selectedValue: string[]) => {
    this.storageService.setJSONItem('selectedOrg', selectedValue)
    this.selectedValue.next(selectedValue);
  };

  getSelectedValue = () => {
    return this.selectedValue.value;
  }
}
