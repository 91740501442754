import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '../services/overlay.service';

@Injectable()
export class OverlayInterceptor implements HttpInterceptor {
  constructor(private readonly overlayService: OverlayService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.includes('files/image')) {
      return next.handle(req);
    }
    const spinnerSubscription: Subscription = this.overlayService.spinner$.subscribe();
    return next.handle(req).pipe(finalize(() => spinnerSubscription.unsubscribe()));
  }
}
