import { InspectionsService } from 'src/app/inspections/inspections.service';
import { CsvExportDialogComponent } from './../../shared/csv-export-dialog/csv-export-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from './../../shared/enums/user-roles.enum';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { AuthService } from '../../auth/auth.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  userSub: Subscription;
  isAuthenticated: boolean = null;
  isAdmin: boolean = null;
  translations: any = {};
  selectedLanguage: string = '';
  languageOptions: {display: string, value: string}[] = [
    { display: 'DE', value: 'de'},
    { display: 'EN', value: 'en'},
    { display: 'PL', value: 'pl'},
    { display: 'CS', value: 'cs'},
    { display: 'FR', value: 'fr'}
  ];

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private inspectionsService: InspectionsService,
    private translateService: TranslateService,
    private storageService: StorageService
    ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang;

    this.userSub = this.authService.currentUser.subscribe(user => {
      this.isAdmin = user?.userModel.role === UserRoles.Admin ? true : false;
      this.isAuthenticated = user?.token && this.isAdmin ? true : user?.token && user?.userModel.agbsAccepted ? true : false;
    });
  }

  onLanguageChange = (selectedLanguage) => {
    this.selectedLanguage = selectedLanguage;
    this.translateService.use(selectedLanguage);
    this.storageService.setItem('lang', selectedLanguage);
  }

  openCSVExportDialog = () => {
    const dialogRef = this.dialog.open(CsvExportDialogComponent, {
      data: {
        title: this.translateService.instant('dialogs.csv.header'),
        text: this.translateService.instant('dialogs.csv.body'),
        confirmBtn: this.translateService.instant('dialogs.csv.export')
      },
      autoFocus: false,
      maxWidth: '90%'
    });

    const sub = dialogRef.componentInstance.confirmSelected.subscribe(
      (startDate:Date) => {
        this.inspectionsService.getCSVFile(startDate.toISOString()).subscribe(response => {
          let myBlob = new Blob([response], {type: 'application/vnd.oasis.opendocument.spreadsheet'});
          let downloadUrl = URL.createObjectURL(myBlob);

          let a = document.createElement('a');
          a.href = downloadUrl;
          a.download = 'inspections_' + startDate.toLocaleDateString('de-AT').replace(/\./gi, '-') +  '_' + new Date().toLocaleDateString('de-AT').replace(/\./gi, '-') + '.csv';
          a.dispatchEvent(new MouseEvent('click'));

          setTimeout(()=> {
            URL.revokeObjectURL.bind(URL, downloadUrl);
          }, 100);
          dialogRef.close();
        })
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      sub.unsubscribe();
    });
  }

  openLogoutConfirmDialog = () => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translateService.instant('dialogs.logout.header'),
        text: this.translateService.instant('dialogs.logout.body'),
        hideStaticText: true
      },
      autoFocus: false,
      maxWidth: '90%'
    });

    const sub = dialogRef.componentInstance.confirmSelected.subscribe(
      (confirmSelected) => {
        if(confirmSelected) {
          this.authService.logout()
            .subscribe(response => {
              if(response && response.status && response.status == 200) {
                this.router.navigate(['login']);
                this.authService.deleteUserData();
              }
            });
        }
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      sub.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }
}
