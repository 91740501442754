import { SnackBarService } from './../../core/services/snackbar.service';
import { AuthService } from '../auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit {
  agbsUrl: string;
  termsCheckbox: boolean = false;

  constructor(
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<AgbComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.agbsUrl = environment.baseUrl + 'files/agbs/de/terms-of-service.html'
  }

  onConfirm = () => {
    this.dialogRef.close();
    this.authService.acceptAGBContent().subscribe(response => {
      if(response.status == 200) {
        this.snackBarService.success(this.translateService.instant('toast.success.agb'), 'X', 2000);
      }
    })
  };

  onCancel = () => {
    this.authService.deleteUserData();
    this.dialogRef.close();
  };

}
