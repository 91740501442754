import { map } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';


@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform {

    constructor(private http: HttpClient, private domSanitizer: DomSanitizer) { }

    transform(url): Observable<SafeUrl> {
      return this.http
        .get(url, { headers: {accept: 'image/jpg'}, responseType: 'blob' })
        .pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))))
    }

}
