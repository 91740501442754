import { SearchService } from './search.service';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() route: string;

  routeName: string = '';
  searchForm: FormGroup = new FormGroup({
    search: new FormControl('')
  })

  constructor(
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.searchForm.controls['search'].setValue(this.searchService.getSearchValue(this.routeName));
    this.searchForm.valueChanges.subscribe(searchFormValue => {
      this.onSearchChange(searchFormValue.search);
    })
    this.computeRouteName();
  }

  computeRouteName = () => {
    switch(this.route) {
      case 'history': {
        this.routeName = 'History';
        break;
      }
      case 'users': {
        this.routeName = 'Users';
        break;
      }
      default: {
        this.routeName = 'Inspections';
      }
    }
  }

  onSearchChange = (searchValue) => {
    this.searchService.setSearchValue(searchValue, this.routeName);
  }

  clearSearch = () => {
    this.searchForm.controls['search'].setValue('');
  }

}
