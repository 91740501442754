import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(): boolean {
    if(this.authService.isAdmin) {
      return true;
    }
    if(this.authService.isAuthenticated()) {
      this.router.navigate(['inspections/ik']);
    } else {
      this.router.navigate(['login']);
    }
    return false;
  }
}
