import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-csv-export-dialog',
  templateUrl: './csv-export-dialog.component.html',
  styleUrls: ['./csv-export-dialog.component.scss']
})
export class CsvExportDialogComponent implements OnInit {
  confirmSelected = new EventEmitter<string>();
  cancelSelected = new EventEmitter<boolean>();
  title: string;
  text: string;
  cancelBtn: string = null;
  confirmBtn: string = null;
  maxDate: Date = new Date();

  csvExportDialogForm: FormGroup = new FormGroup({
    startDate: new FormControl("", [Validators.required, Validators.maxLength(200)])
  });

  constructor(
    public dialogRef: MatDialogRef<CsvExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.title = data.title;
    this.text = data.text;
    this.cancelBtn = data.cancelBtn;
    this.confirmBtn = data.confirmBtn;
  }

  ngOnInit(): void {}

  onConfirm = () => {
    this.confirmSelected.emit(this.csvExportDialogForm.controls['startDate'].value);
    // this.dialogRef.close();
  };

  onCancel = () => {
    this.cancelSelected.emit(true);
    this.dialogRef.close();
  };

}
