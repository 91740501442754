<div class="search-container" [formGroup]="searchForm" [ngClass]="{'users': route == 'users'}">
  <mat-form-field class="search-field">
    <mat-label>
      {{ 'search' | translate }}
    </mat-label>
    <input matInput formControlName="search">
    <mat-hint>
      <mat-icon>search</mat-icon>
      {{ (route === 'users' ? 'search-hint-user' : route === 'ie' ? 'search-hint-ie' : 'search-hint-ik-iv') | translate}}
    </mat-hint>
    <span mat-icon-button matSuffix (click)="clearSearch()">
      <mat-icon class="clear-icon">{{ searchForm.controls.search.value.length ? 'highlight_off': ''}}</mat-icon>
    </span>
  </mat-form-field>
</div>
