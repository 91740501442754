import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$"),
    ])
  });

  constructor(
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  onCancel = () => {
    this.dialogRef.close();
  }

  onSubmit = () => {
    this.authService.forgotPassword(this.forgotPasswordForm.value.email).subscribe(response => {
      if(response && response.status) {
        this.dialogRef.close();
        this.snackBarService.success(this.translateService.instant('toast.success.forgot-pass'), 'X', 2000);
      }
    })
  }

}
