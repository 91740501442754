import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class USGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if(this.authService.isUS) {
      return true;
    }
    if(this.authService.isAuthenticated()) {
      this.router.navigate(['users']);
      return false;
    } else {
      const fullPath = segments.reduce((path, currentSegment) => {
        return `${path}/${currentSegment.path}`;
      }, '');
      this.router.navigate(['login'],{queryParams: { returnUrl: fullPath}});
    }
    return false;
  }
}
