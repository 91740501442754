import { AutoLogoutService } from './core/services/auto-logout.service';
import { StorageService } from './core/services/storage.service';
import { ConfigsService } from './core/services/configs.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  userActivity: any;

  constructor(
    private configsService: ConfigsService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private autoLogoutService: AutoLogoutService
  ) {
    const userLang: string = this.storageService.getItem('lang') || this.translateService.getBrowserLang().substring(0,2);
    translateService.use(userLang);
  }

  ngOnInit(): void {
    this.configsService.getConfigs();
  }
}
