import { IPortalUser } from '../interfaces';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IInspectionModel } from '../interfaces';
import { ConfigsService } from 'src/app/core/services/configs.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchValue = new BehaviorSubject<string>('');
  currentSearchValue = this.searchValue.asObservable();
  selectedLanguage: string;

  routeName = '';

  constructor(
    private configsService: ConfigsService,
    private translateService: TranslateService
  ) {
    this.selectedLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  setSearchValue = (searchValue: string, routeName) => {
    this.searchValue.next(searchValue);
    this.routeName = routeName;
  }

  getSearchValue = (routeName) => {
    if (routeName === this.routeName) {
    } else {
      this.searchValue.next('');
    }
    return this.searchValue.value;
  }

  searchIKIE = (inspectionsArray: IInspectionModel[]): IInspectionModel[] => {
    return this.searchInternal(inspectionsArray, true);
  }

  searchIVHistory = (inspectionsArray: IInspectionModel[]): IInspectionModel[] => {
    return this.searchInternal(inspectionsArray, false);
  }

  searchInternal = (inspectionsArray: IInspectionModel[], isIkIe: boolean): IInspectionModel[] => {
    if (this.searchValue.value) {
      return inspectionsArray.filter(inspection => {
        const config = this.configsService.configs[inspection.jsonVersion];
        const language = config.languages[this.selectedLanguage] ? this.selectedLanguage : 'de';
        const lowerCaseSearch = this.searchValue.value.toLowerCase();

        return inspection.userDataModel.inspectorName?.toLowerCase().includes(lowerCaseSearch) ||
          inspection.overviewModel.serialnumber?.toLowerCase().includes(lowerCaseSearch) ||
          config.languages[language][inspection.overviewModel.manufacturer]?.toLowerCase().includes(lowerCaseSearch) ||
          inspection.overviewModel.machineType?.toLowerCase().includes(lowerCaseSearch) ||
          config.languages[language][inspection.dataModel.machineSubCategoryKey]?.toLowerCase().includes(lowerCaseSearch) ||
          (isIkIe && inspection.dataModel.customerCompanyName?.toLowerCase().includes(lowerCaseSearch));
      });
    } else {
      return inspectionsArray;
    }
  }
  searchUsers = (usersArray: IPortalUser[]) => {
    return usersArray.filter(user =>
      user.email.toLocaleLowerCase().includes(this.searchValue.value.toLowerCase()) ||
      (user.firstName + ' ' + user.lastName).toLocaleLowerCase().includes(this.searchValue.value.toLowerCase())
      // user.lastName.toLocaleLowerCase().includes(this.searchValue.value.toLowerCase())
      // user.companyName.toLocaleLowerCase().includes(this.searchValue.value.toLowerCase()) Deep add company name once added by Backend
    );
  }
}
