import { SnackBarService } from "src/app/core/services/snackbar.service";
import { AuthService } from "../../auth/auth.service";
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

// Deep Shah add translations for all the errors

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isAuth = this.authService.isAuthenticated();
    if (isAuth) {
      const authHeader = "Bearer " + this.authService.getToken();
      const authReq = req.clone({
        headers: req.headers.set("Authorization", authHeader),
      });
      return next
        .handle(authReq)
        .pipe(catchError((error) => this.handleAuthError(error, req.method)));
    } else {
      return next
        .handle(req)
        .pipe(catchError((error) => this.handleAuthError(error, req.method)));
    }
  }

  private handleAuthError(err: HttpErrorResponse, method): Observable<any> {
    // console.log(err);
    switch (err.status) {
      case 400: {
        this.snackBarService.error('Invalid Request Body (' + err.error.message + ')', 'X', 2000);
        break;
      }
      case 401: {
        if(err.error.message == 'Token invalid') {
          this.authService.deleteUserData();
          this.snackBarService.error(this.translateService.instant('invalid_token_error'), 'X', 2000);
        } else if(err.url.includes('account')) {
          this.openInfoConfirmDialog();
          return throwError(err.status);
        }
        break;
      }
      case 403: {
        this.openBlockedDialog();
        break;
      }
      case 404: {
        if(err.url.includes('account')) {
          this.authService.deleteUserData();
          this.snackBarService.error('User Not Found', 'X', 2000);
        } else {
          this.snackBarService.error(err.error.message || this.translateService.instant('not_found'), 'X', 2000);
        }
        break;
      }
      case 409: {
        if(err.url.includes('password')) {
          this.authService.deleteUserData();
          this.snackBarService.error(this.translateService.instant('password_reset_conflict'), 'X', 2000);
        } else if(err.url.includes('inspections')) {
          // Deep Shah add translations
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: this.translateService.instant('inspection_save_error_title'),
              text: this.translateService.instant('inspection_save_error_body'),
              hideStaticText: true,
              hideCancelBtn: true
            },
            autoFocus: false,
            maxWidth: '90%'
          });
        } else if(err.url.includes('users')) {
          if(method == 'PUT') {
            this.snackBarService.error(this.translateService.instant('email_already_taken'), 'X', 2000);
          } else if(method == 'POST') {
            this.snackBarService.error(this.translateService.instant('user_already_exists'), 'X', 2000);
          }
        }
        break;
      }
      case 422: {
        this.snackBarService.error(this.translateService.instant('invalid_email'), 'X', 2000);
        break;
      }
      case 429: {
        return throwError(err.status);
        break;
      }
      case 500: {
        this.snackBarService.error(err.error.message || 'Internal Server Error', 'X', 2000);
        break;
      }
    }
    return of(err.error.message);
  }


  openInfoConfirmDialog = () => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translateService.instant('password_invalid_title'),
        text: this.translateService.instant('password_invalid_body'),
        hideStaticText: true,
        hideCancelBtn: true
      },
      autoFocus: false,
      maxWidth: '90%'
    });
  }
  openBlockedDialog = () => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translateService.instant('blocked_user_title'),
        text: this.translateService.instant('blocked_user_body'),
        hideStaticText: true,
        hideCancelBtn: true
      },
      autoFocus: false,
      maxWidth: '90%'
    });
  }
}
