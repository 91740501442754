import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppInitService {
  // This is the method you want to call at bootstrap
  // Important: It should return a Promise'
  private appConfig;
  constructor() {}

  public init() {
    return from(
      fetch('assets/app-config.json').then((response) => {
        return response.json();
      })
    )
      .pipe(
        map((config) => {
          this.appConfig = config;
          return;
        })
      )
      .toPromise();
  }

  getConfig = () => {
    return this.appConfig;
  }
}
