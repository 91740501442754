<div fxFlex="column" class="csv-export-dialog dialog" [formGroup]="csvExportDialogForm">

  <div class="dialog-header">
    {{ title }}
  </div>

  <div class="dialog-container" fxLayout="column">

    <div class="dialog-body">
      {{ text }}

      <div>
        <mat-form-field class="calendar-input" appearance="outline">
          <mat-label>{{ 'dialogs.csv.start-date' | translate }}</mat-label>
          <input formControlName="startDate" matInput [matDatepicker]="startPicker" placeholder="dd.mm.yyyy" [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="space-between">
      <button class="btn btn-grey" mat-button type="button" (click)="onCancel()">
        {{ cancelBtn || 'cancel' | translate }}
      </button>
      <button class="btn btn-primary" mat-button type="button" (click)="onConfirm()" [disabled]="csvExportDialogForm.invalid">
        {{ confirmBtn || 'yes' | translate }}
      </button>
    </div>
  </div>
</div>
