<div class="outer-container" fxLayout="row" fxLayoutAlign="center center">
  <div *ngIf="!loginSuccessful" class="user-card" fxLayout="column" fxFlex="25" fxFlex.xs="90" fxFlex.lt-lg="30" fxFlex.lt-md="40">
    <div class="card-header">
      <ul class="nav nav-tabs nav-fill">
        <li class="nav-item" (click)="setUserType('US')">
          <a [ngClass]="{'active': !isAdminMode}" class="nav-link left">{{ 'users.US' | translate }}</a>
        </li>
        <li class="nav-item" (click)="setUserType('ADMIN')">
          <a [ngClass]="{'active': isAdminMode}" class="nav-link right">{{ 'users.Admin' | translate }}</a>
        </li>
      </ul>
    </div>
    <form [formGroup]="loginForm" fxFlex="65" class="card-body" fxLayout="column" fxLayoutAlign="space-between">
      <div fxFlex="60" fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxFlex="50" fxLayoutAlign="center">
          <mat-form-field fxFlex="90" floatLabel="never" hideRequiredMarker>
            <input
              class="input-center"
              matInput
              formControlName="email"
              type="email"
              [placeholder]="'email' | translate"
              required
              email
            >
          </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex="40" fxLayoutAlign="center">
          <mat-form-field id="passwordField" fxFlex="90" floatLabel="never" hideRequiredMarker>
            <input
              id="passwordInput"
              (click)="isCapsLockOn($event)"
              (keydown)="isCapsLockOn($event)"
              (blur)="capsLockOn = false"
              class="input-center"
              matInput
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              [placeholder]="'password' | translate"
              autocomplete="on"
              required
            >
              <span (click)="togglePasswordVisibility()" class="visibility-btn" mat-icon-button matSuffix>
                  <mat-icon>{{ showPassword ? 'visibility_off': 'visibility' }}</mat-icon>
              </span>
          </mat-form-field>
        </div>
        <div class="caps-on">{{ (capsLockOn ? 'caps_warning' : '') | translate}}</div>
        <div [ngClass]="!captchaNeeded && !this.captcha ? 'hide' : ''" fxLayout="row" fxFlex="40" fxLayoutAlign="center">
          <div fxFlex="90" class="frc-captcha" data-sitekey="FCMR8OVPNVVSDT6A" #frccaptcha></div>
        </div>
      </div>
      <div fxFlex="25" fxLayoutAlign="center center">
        <button
            class="btn btn-primary"
            type="submit"
            [disabled]="(loginForm.status === 'INVALID' && loginForm.touched) || captchaNeeded"
            fxFlex="90"
            (click)="onLogin()"
        >
          {{ 'login' | translate}}
        </button>
      </div>
      <div fxFlex="5" class="forgot-password" fxLayoutAlign="flex-end" (click)="onForgotPassword()">
        {{ 'password-change' | translate }}
      </div>
    </form>
  </div>
</div>
