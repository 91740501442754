<div fxFlex="column" class="agb dialog">

  <div class="dialog-header">
    {{ 'dialogs.agb.header' | translate }}
  </div>

  <div class="dialog-container" fxLayout="column">
    <div class="dialog-body">
      {{ 'dialogs.agb.body' | translate }}
    </div>

    <div class="check-box-container">
      <mat-checkbox [(ngModel)]="termsCheckbox">
        {{ 'dialogs.agb.checkbox-label' | translate }}
      </mat-checkbox>
      <a class="link" [href]="agbsUrl" target="_blank">
        {{ 'dialogs.agb.checkbox-link' | translate }}
      </a>
    </div>

    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="space-between">
      <div>
        <button class="btn btn-grey" mat-button type="button" (click)="onCancel()">
          {{ 'cancel' | translate }}
        </button>
      </div>
      <div>
        <button [disabled]="!termsCheckbox" class="btn btn-primary" mat-button type="button" (click)="onConfirm()">
          {{ 'understand' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
