<mat-expansion-panel class="grey-bg" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ config.languages[selectedLanguage][section.title] || section.title }}
      &nbsp;
      <span *ngIf="inspectionType">
        ({{ inspectionType | translate }})
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="image-chips-container">
    <div *ngFor="let component of section.components; let i = index">
      <div *ngIf="component?.urls?.length > 0 && inspectionDetail.components[component.componentID]" class="image-chip" [ngClass]="{'selected': selectedChipIndex == i}" (click)="onChipSelect(i)">
        {{ config.languages[selectedLanguage][component.name] || component.name }}
      </div>
    </div>
  </div>

  <div [ngClass]="{'is-comparison' : isComparison, 'image-container': isImageAvailable}">
    <app-carousel [slides]="selectedImageUrls" [isMachinePictures]="true"></app-carousel>
  </div>
</mat-expansion-panel>
