import { IOrganizationModel } from '../interfaces';

export class User {
  constructor(
    public itemid: string,
    private _token: string,
    public type: number,
    public userModel: {
      agbsAccepted: boolean,
      email: string,
      firstName: string,
      itemid: string,
      language: string,
      lastName: string,
      organizations: IOrganizationModel[],
      phone: string,
      role: string,
      type: number,
      currency: string
    }

  ) {}

  set token(token) {
    this._token = token;
  }

  get token() {
    return this._token;
  }

}
