import { FilterService } from './filter.service';
import { Component, Input, OnInit } from '@angular/core';
import { IPageFilterModel } from '../interfaces';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() route: string;
  inspectionStatusFilter: IPageFilterModel;
  allComplete: boolean;

  constructor(private filterService: FilterService) { }

  ngOnInit(): void {
    this.inspectionStatusFilter = this.filterService.getFilerValue(this.route);
    this.updateAllComplete();
  }

  updateAllComplete() {
    this.allComplete = this.inspectionStatusFilter.subFilters != null && this.inspectionStatusFilter.subFilters.every(f => f.checked);
  }

  someComplete(): boolean {
    if (this.inspectionStatusFilter.subFilters == null) {
      return false;
    }
    return this.inspectionStatusFilter.subFilters.filter(f => f.checked).length > 0 && !this.allComplete;
  }

  setAll(checked: boolean) {
    this.allComplete = checked;
    if (this.inspectionStatusFilter.subFilters == null) {
      return;
    }
    this.inspectionStatusFilter.subFilters.forEach(f => f.checked = checked);
    this.filterChanged();
  }

  filterChanged = () => {
    this.filterService.setFilterValue(this.inspectionStatusFilter, this.route);
  }
}
