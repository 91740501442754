<div class="outer-container grey-bg">
  <div class="title-container" fxLayoutAlign="space-between center">
    <div class="title">
      {{ 'account.reset-password.title' | translate }}
    </div>
  </div>

  <div class="body-container" fxLayoutAlign="space-around center">
    <form [formGroup]="resetPasswordForm" fxLayout="column" fxLayoutAlign="space-between">
      <mat-form-field floatLabel="never" hideRequiredMarker>
        <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'"
        [placeholder]="'password' | translate" autocomplete="on" required>
        <span (click)="togglePasswordVisibility()" class="visibility-btn" mat-icon-button matSuffix>
          <mat-icon>{{ showPassword ? 'visibility_off': 'visibility' }}</mat-icon>
        </span>
      </mat-form-field>
      
      <mat-form-field floatLabel="never" hideRequiredMarker>
        <input matInput formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'"
        [placeholder]="'confirm-password' | translate" autocomplete="on" required>
        <span (click)="toggleConfirmPasswordVisibility()" class="visibility-btn" mat-icon-button matSuffix>
          <mat-icon>{{ showConfirmPassword ? 'visibility_off': 'visibility' }}</mat-icon>
        </span>
        <mat-error
          *ngIf="resetPasswordForm.controls.confirmPassword.touched && resetPasswordForm.controls.confirmPassword.errors?.mustMatch"
          class="error">
          {{ 'pass-not-match' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="passwordViolations" >
        <span *ngFor="let error of passwordViolation">{{error}}</span>
      </div>

      <button class="btn btn-primary" type="submit" [disabled]="(resetPasswordForm.invalid)" fxFlex="90"
        (click)="onUpdatePassword()">
        {{ 'account.reset-password.title' | translate }}
      </button>
    </form>
  </div>

</div>
