<div class="select-container" [formGroup]="selectForm">
  <mat-form-field  class="select-field">
    <mat-label>{{ 'organization' | translate }}</mat-label>
    <mat-select formControlName="select" multiple>
      <mat-option *ngFor="let organization of organizationList" [value]="organization.itemid" style="color: black;">
        {{organization.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
