import { ListResponse } from 'src/app/shared/interfaces';
import { StorageService } from './storage.service';
import { CallAPIService } from './call-api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigsService {
  configsUrl: string = 'configs/sync';
  configs: any = {};
  private configsReady = new BehaviorSubject<boolean>(false);
  isConfigsReady = this.configsReady.asObservable();

  constructor(
    private callAPIService: CallAPIService,
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  getConfigs = () => {
    const availableConfigs = this.getConfigsFromLocal();
    if (availableConfigs) { this.configs = availableConfigs; }
    this.callAPIService.callGetAPI(this.configsUrl).subscribe((response: ListResponse<any>) => {
      response.list.map((configDetail, index) => {
        const lastRequest = index === (response.overallsize - 1);
        if (!availableConfigs || !availableConfigs[configDetail.itemid]) {
          this.getConfigFile(configDetail.location, lastRequest);
        } else if (lastRequest) { this.configsReady.next(true); }
      });
    });
  }

  private getConfigFile = (url, lastRequest) => {
    this.http.get(url).subscribe((response: any) => {
      this.configs[response.version] = {};
      this.configs[response.version].machines = this.parseMachinesArray(response.machines);
      this.configs[response.version].languages = this.parseLanguageArray(response.languages);
      this.storeConfigsToLocal();
      if (lastRequest) { this.configsReady.next(true); }
    });
  }

  private parseMachinesArray = (machinesArray) => {
    const machinesObject = {};
    machinesArray.map(machine => {
      machinesObject[machine.name] = machine;
      machinesObject[machine.name].product_categories = this.parseProductCategoriesArray(machine.product_categories);
      // machinesObject[machine.name]['perspectives'] = this.parsePerspectivesArray(machine.perspectives);

      machinesObject[machine.name].components = this.flatComponentsStructure(machine.perspectives);

      delete machine.perspectives;
    });
    return machinesObject;
  }

  private flatComponentsStructure = (perspectives) => {
    const componentsObject = {};
    perspectives.map((perspective) => {
      perspective.components.map((component) => {
        if (component.sub_components && component.sub_components.length > 0) {
          component.sub_components.map((subComponent) => {
            componentsObject[subComponent.id] = subComponent;
          });
        }
        // else {
        componentsObject[component.id] = {...component, perspective: perspective.name};
        // }
      });
    });
    // console.log(componentsObject);
    return componentsObject;
  }

  private parseProductCategoriesArray = (productCategoriesArray) => {
    const productsCategoryObject = {};
    productCategoriesArray.map(productCategory => {
      productsCategoryObject[productCategory.name] = productCategory;
    });
    return productsCategoryObject;
  }

  private parsePerspectivesArray = (perspectivesArray) => {
    const perspectivesObject = {};
    perspectivesArray.map(perspective => {
      perspective.components = this.parseComponentsArray(perspective.components);
      perspectivesObject[perspective.name] = perspective;
    });
    return perspectivesObject;
  }

  private parseComponentsArray = (componentsArray) => {
    const componentsObject = {};
    componentsArray.map(component => {
      componentsObject[component.id] = component;
      if (component.sub_components?.length > 0) {
        component.sub_components = this.parseSubComponentsArray(component.sub_components);
      }
    });
    return componentsObject;
  }

  private parseSubComponentsArray = (subComponentsArray) => {
    const subComponentsObject = {};
    subComponentsArray.map(component => {
      subComponentsObject[component.id] = component;
    });
    return subComponentsObject;
  }

  private parseLanguageArray = (languagesArray) => {
    const languagesObject = {};
    languagesArray.map(language => {
      languagesObject[language.language_code] = language.translations;
    });
    return languagesObject;
  }

  storeConfigsToLocal = () => {
    this.storageService.setConfigs(this.configs);
  }

  getConfigsFromLocal = () => {
    return this.storageService.getConfigs();
  }

  // isConfigsLoaded = () => {
  //   // return Object.keys(this.configs).length !== 0;
  //   for(var i in this.configs) return false;
  //   return true;
  // }

}
