import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OverlayModule } from "./overlay/overlay.module";
import { LayoutComponent } from "./layout/layout.component";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { OverlayInterceptor } from "./interceptors/overlay.interceptor";

@NgModule({
  declarations: [LayoutComponent],
  imports: [CommonModule, RouterModule, OverlayModule, SharedModule],
  exports: [OverlayModule, LayoutComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OverlayInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
