import { Component, Input, OnInit } from '@angular/core';
import { IInspectionModel } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-machine-pictures',
  templateUrl: './machine-pictures.component.html',
  styleUrls: ['./machine-pictures.component.scss']
})
export class MachinePicturesComponent implements OnInit {
  @Input() config: any;
  @Input() section: any;
  @Input() inspectionDetail: IInspectionModel;
  @Input() selectedLanguage: string;
  @Input() isComparison?: boolean;
  @Input() inspectionType?: string;

  machineCategory: string;
  isImageAvailable: boolean = false;

  selectedChipIndex: number;
  selectedImageUrls: string[] = [];
  componentsWithImages: number[] = [];

  constructor() { }

  ngOnInit(): void {
    this.section = JSON.parse(JSON.stringify(this.section));
    this.machineCategory = this.inspectionDetail.dataModel.machineCategoryKey;
    this.parseInspectionDetailMachinePictures();
    this.onChipSelect(this.componentsWithImages[0]);
  }

  parseInspectionDetailMachinePictures = () => {
    this.section.components.map((component, index) => {
      const inspectionComponent = this.inspectionDetail.components[component.componentID];
      if(inspectionComponent) {
        component['urls'] = inspectionComponent.urlValues;
        component['name'] = inspectionComponent.componentName;
        if(component['urls'].length > 0) {
          this.componentsWithImages.push(index);
          this.isImageAvailable = true;
        };
      }
    })
  }

  onChipSelect = (index: number) => {
    this.selectedChipIndex = index;
    this.selectedImageUrls = this.section.components[index]?.urls;
  }
}
