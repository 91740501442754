<div class="sort-container" fxLayout.lt-md="row" fxLayoutAlign.lt-md="center" [formGroup]="sortForm">
  <mat-button-toggle-group appearance="legacy" formControlName="field" name="sortField">
    <mat-button-toggle value="date">{{ 'date' | translate }}</mat-button-toggle>
    <mat-button-toggle value="type">{{ 'type' | translate }}</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-button-toggle-group class="direction" appearance="legacy" formControlName="direction" name="sortDirection">
    <mat-button-toggle value="asc">
      <mat-icon class="up-icon">
        north
      </mat-icon>
      <mat-icon class="sort-icon asc">
        sort
      </mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="dsc">
      <mat-icon class="down-icon">
        south
      </mat-icon>
      <mat-icon class="sort-icon">
        sort
      </mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
