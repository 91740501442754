<mat-expansion-panel class="grey-bg" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ config.languages[selectedLanguage][section.title] || section.title | translate}}
      &nbsp;
      <span *ngIf="inspectionType">
        ({{ inspectionType | translate }})
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div [fxLayout]="columnLayout ? 'column': section.layout" fxLayout.lt-md="column" fxLayoutAlign="space-between">
    <ng-container *ngIf="section.title !== 'further-information'">
      <ng-container *ngFor="let column of section.columns">
        <div *ngIf="column.isVisible" [fxFlex]="column.width">
          <div *ngFor="let category of column.subCategories" [style.display]="category.components.length > 0 ? 'block' : 'none'">
            <div *ngIf="category.isVisible" class="sub-category">
              {{ (config.languages[selectedLanguage][category.title] || category.title) | translate }}
            </div>

            <div class="component-container" fxLayout="column">
              <ng-container *ngFor="let component of category.zeroColumn; let i = index">
                <ng-template *ngTemplateOutlet="componentTemplate; context: {component : component, class: 'my-2'}"></ng-template>
              </ng-container>

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between">
                <div fxFlex="49.7">
                  <ng-container *ngFor="let component of category.firstColumn; let i = index">
                    <ng-template *ngTemplateOutlet="componentTemplate; context: {component : component, class: 'my-4'}"></ng-template>
                  </ng-container>
                </div>

                <div fxFlex="49.7" fxLayout.lt-sm="column">
                  <ng-container *ngFor="let component of category.secondColumn">
                    <ng-template *ngTemplateOutlet="componentTemplate; context: {component : component, class: 'my-4'}"></ng-template>
                  </ng-container>
                </div>
              </div>
            </div>

            <ng-template #componentTemplate let-component="component" let-class="class">
              <ng-container *ngIf="(component.componentID == null || inspectionDetail.components[component.componentID]) && component.componentType !== 'radio_button_extension_devices'">
                <div *ngIf="component.type == ComponentTypes.normal && component.values.length > 0" class="component" [ngClass]="class">
                  <div *ngIf="component.title" class="component__title" fxLayout="row" fxLayoutAlign="space-between center">
                    {{ component.title }}
                    <img *ngIf="component.showIcon"
                      src="{{config.machines[inspectionDetail.dataModel.machineCategoryKey].product_categories[inspectionDetail.dataModel.machineSubCategoryKey]?.icon
                      || config.machines[inspectionDetail.dataModel.machineCategoryKey]['icon'] }}" />
                  </div>
                  <div class="component__value" [ngClass]="{'unavailable': (!isComparison && component.unavailable) || (isComparison && !component.isEqual)}">
                    {{ component.values }} {{ component.values ? component.unit : '' }}

                    <img *ngIf="component.icon" class="icon" [src]='"assets/images/" + component.icon + ".png"'>
                    <mat-icon *ngIf="component.matIcon" class="error">{{component.matIcon}}</mat-icon>
                  </div>
                </div>
                <div *ngIf="component.type == ComponentTypes.carousel && component.urls?.length > 0" class="image-container one-four">
                  <app-carousel [slides]="component.urls" [imageTitles]="component.values" [imageDescriptions]="component.descriptions" [section]="section.title"></app-carousel>
                </div>
              </ng-container>
            </ng-template>
          </div>
      </div>
      </ng-container>
    </ng-container>


    <ng-container *ngIf="section.title == 'further-information'">
      <div *ngFor="let column of section.columns" [fxFlex]="column.width" class="further-information">
        <div *ngFor="let category of column.subCategories" [style.display]="category.components.length > 0 ? 'block' : 'none'">
          <div *ngIf="category.isVisible" class="sub-category">
            {{ ( config.languages[selectedLanguage][category.title] || category.title ) | translate }}
          </div>

          <div class="category-components">
            <div class="component-container" *ngFor="let component of category.components; let i = index" [fxFlex]="component.width" [fxFlex.lt-sm]="100">
              <ng-container *ngIf="(component.componentID == null || inspectionDetail.components[component.componentID]) && component.componentType !== 'radio_button_extension_devices'">
                <div *ngIf="component.type == ComponentTypes.normal && component.values.length > 0" class="component" [ngClass]="{'comment': component.isComment }">
                  <div *ngIf="component.title" class="component__title" fxLayout="row" fxLayoutAlign="space-between center">
                    {{ component.title }}
                  </div>
                  <div class="component__value" [ngClass]="{'unavailable': (!isComparison && component.unavailable) || !component.isEqual}">
                    {{ component.values }} {{ component.values ? component.unit : '' }}
                    <img *ngIf="component.icon" class="icon" [src]='"assets/images/" + component.icon + ".png"'>
                    <mat-icon *ngIf="component.matIcon" class="error">{{component.matIcon}}</mat-icon>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</mat-expansion-panel>
